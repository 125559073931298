import 'reactflow/dist/style.css';
import './FlowBuilder.scss';

import {
  FlowBuilderContext,
  RenameModalData,
  TAudienceSlideoutProps,
  TContentSlideoutProps,
  TPushSlideoutProps,
  TWebhookSlideoutProps,
} from './context/flow-builder.context';
import React, { useEffect, useMemo, useState } from 'react';
import { mapBranchedJourney, mapLinearJourney } from 'pages/FlowBuilder/helpers/flow-builder.helpers';
import { Node, Edge, ReactFlowProvider } from 'reactflow';
import { useAppSelector as useSelector } from 'hooks/reduxHooks';
import { useHistory, useRouteMatch } from 'react-router';

import ActionsHeader from 'components/ExperienceCanvas/HeaderLayout/ActionsHeader/ActionsHeader';
import ContentManager from 'components/ExperienceCanvas/ContentManager/ContentManager';
import ErrorSplash from 'components/ExperienceCanvas/ErrorSplash/ErrorSplash';
import { FlowBuilderRouteParams } from './types/flow-builder.types';
import FlowCanvas from 'components/FlowBuilder/FlowCanvas/FlowCanvas';
import IconContentFlow from 'pages/FlowBuilder/assets/icons/IconContentFlow';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';
import PrimaryHeader from 'components/ExperienceCanvas/HeaderLayout/PrimaryHeader/PrimaryHeader';
import PushSidePanel from 'components/FlowBuilder/FlowSidePanels/PushSidePanel/PushSidePanel';
import RenameNodeModal from 'components/FlowBuilder/FlowModals/RenameNodeModal/RenameNodeModal';
import ScheduleSidePanel from 'components/FlowBuilder/FlowSidePanels/ScheduleSidePanel/ScheduleSidePanel';
import TargetAudienceSidePanel from 'components/FlowBuilder/FlowSidePanels/TargetAudienceSidePanel/TargetAudienceSidePanel';
import WebhookSidePanel from 'components/FlowBuilder/FlowSidePanels/WebhookSidePanel/WebhookSidePanel';
import { getDefaultLanguage } from 'helpers/templated-experience.helper';
import useContentCreationManager from 'hooks/useContentCreationManager';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useJourneyInstance from 'hooks/useJourneyInstance';
import useSettings from 'hooks/useSetting';
import { WEBHOOK_INIT } from 'constants/webhook.constants';

const MAIN_CLASS = 'flow-builder';
const LOADING_ICON_CONTAINER = 'loading-icon-container';
const CONTENT_SIDE_PANEL = 'content-side-panel';
const CLASSES = {
  FLOW_CONTAINER: `${MAIN_CLASS}__flow-container`,
  LOADING_ICON: `${LOADING_ICON_CONTAINER}__icon`,
  MODALS: `${MAIN_CLASS}__modals`,
  SIDE_PANELS: `${MAIN_CLASS}__side-panels`,
};

const defaultContentSlideoutProps = {
  isOpen: false,
  headerInfo: {
    goBackTitle: '',
    goBackIcon: <IconContentFlow />,
    goBackSubTitle: '',
    mainTitle: '',
  },
};

function FlowBuilder() {
  const [audienceContext, setAudienceContext] = useState<TAudienceSlideoutProps>({
    isOpen: false,
    isViewOnlyMode: false,
  });
  const [contentContext, setContentContext] = useState<TContentSlideoutProps>(defaultContentSlideoutProps);
  const [isScheduleSidePanelOpen, setScheduleSidePanelOpen] = useState(false);
  const [pushContext, setPushContext] = useState<TPushSlideoutProps>({ isOpen: false });
  const [renameModalData, setRenameModalData] = useState<RenameModalData | null>(null);
  const [stepIndex, setStepIndex] = useState(-1);
  const [webhookContext, setWebhookContext] = useState<TWebhookSlideoutProps>({ isOpen: false, webhook: WEBHOOK_INIT });
  const { flags } = useFeatureFlag();
  const { languages } = useSettings();
  const { pid: projectId, id: journeyId } = useRouteMatch<FlowBuilderRouteParams>().params;
  const defaultLanguage = getDefaultLanguage(languages);
  const history = useHistory();
  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);
  const isTemplate =
    urlParams.get('fromTemplateLibrary')?.toLowerCase() === 'true' || urlParams.get('mode') === 'create';
  const isViewFlow = urlParams.get('viewFlow')?.toLowerCase() === 'true';
  const parentEntityId = urlParams.get('parentEntityId');
  const parentEntityType = urlParams.get('parentEntityType');

  const {
    journeyInstance,
    isLoading,
    fetch: refetch,
    error: journeyError,
  } = useJourneyInstance(journeyId, isTemplate, undefined, parentEntityId ?? '', parentEntityType ?? '', projectId);

  useContentCreationManager(true);

  const flowBuilderContextValue = {
    modals: {
      rename: {
        data: renameModalData,
        setData: setRenameModalData,
      },
    },
    sidePanels: {
      // feel like we can just ... keep stepIndex at root context level no?
      targetAudience: {
        ...audienceContext,
        setAudienceContext,
        setStepIndex,
        stepIndex,
      },
      schedule: {
        isOpen: isScheduleSidePanelOpen,
        setIsOpen: setScheduleSidePanelOpen,
        setStepIndex,
        stepIndex,
      },
      push: {
        ...pushContext,
        setPushContext,
        setStepIndex,
        stepIndex,
      },
      content: {
        ...contentContext,
        setContentContext,
        setStepIndex,
        stepIndex,
      },
      webhook: {
        ...webhookContext,
        stepIndex,
        setWebhookContext,
        setStepIndex,
      },
    },
  };

  const { journeyState, ruleState, pushState, contentState, webhookState, dependencyGraph } = useSelector((state) => ({
    journeyState: state.te.journey,
    ruleState: state.te.rule,
    pushState: state.te.push,
    contentState: state.te.content,
    webhookState: state.te.webhook,
    dependencyGraph: state.te.journey.dependencyGraph,
  }));

  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);

  useEffect(() => {
    if (!!journeyInstance.id && journeyId !== journeyInstance.id) {
      urlParams.set('fromTemplateLibrary', 'false');
      history.replace(`/project/${projectId}/experiences/${journeyInstance.id}/flow-builder?${urlParams.toString()}`);
    }
  }, [history, projectId, journeyId, journeyInstance.id, urlParams]);

  useEffect(() => {
    const steps = journeyState.steps;

    if (steps?.length > 0) {
      // Check if any step has children before loading the flow visualizer
      const isFlowTemplate = steps.some((step) => !!step.children && step.children.length > 0);

      if ((!isFlowTemplate || flags['tx_demo']) && !isViewFlow) {
        // Re-direct to touchpoint overview page for non-flow templates
        history.push(`/project/${projectId}/experiences/${journeyId}/overview${window.location.search}`);
      }

      (async () => {
        /**
         * No better way to determine if the step payload would be rendered
         * as a linear flow or a branched flow other than checking
         * if the first step has `id` property
         */
        if (steps[0].id) {
          const { nodes, edges } = await mapBranchedJourney(
            journeyState,
            ruleState,
            pushState,
            contentState,
            webhookState,
            defaultLanguage,
            dependencyGraph,
          );

          setNodes(nodes as Node[]);
          setEdges(edges as Edge[]);
        } else {
          const { nodes, edges } = await mapLinearJourney(
            journeyState,
            ruleState,
            pushState,
            contentState,
            webhookState,
            defaultLanguage,
            dependencyGraph,
          );

          setNodes(nodes as Node[]);
          setEdges(edges as Edge[]);
        }
      })();
    }
  }, [
    contentState,
    defaultLanguage,
    dependencyGraph,
    history,
    isViewFlow,
    journeyId,
    journeyState,
    projectId,
    pushState,
    ruleState,
    webhookState,
    flags,
  ]);

  return isLoading ? (
    <div className={LOADING_ICON_CONTAINER} aria-label="experience is loading">
      <LoadingIcon className={CLASSES.LOADING_ICON} />
    </div>
  ) : journeyError ? (
    <ErrorSplash error={journeyError} />
  ) : (
    <FlowBuilderContext.Provider value={flowBuilderContextValue}>
      <div className={MAIN_CLASS}>
        <PrimaryHeader journeyInstance={journeyInstance} refetch={refetch} />
        <ActionsHeader journeyInstance={journeyInstance} />
        <div className={CLASSES.FLOW_CONTAINER}>
          {/*
            Functionality not implemented yet
            (out of scope for the Dec 2023 Product Summit)
            <FlowComponentsPanel />
          */}
          <FlowCanvas nodes={nodes} edges={edges} />
        </div>
      </div>
      <div className={CLASSES.MODALS}>
        <RenameNodeModal />
      </div>
      <div className={CLASSES.SIDE_PANELS}>
        <TargetAudienceSidePanel />
        <ScheduleSidePanel />
        <PushSidePanel />
        <ContentManager
          {...contentContext}
          stepIdx={stepIndex}
          onClickClose={() => {
            setStepIndex(-1);
            setContentContext(defaultContentSlideoutProps);
          }}
          headerInfo={contentContext.headerInfo}
          className={CONTENT_SIDE_PANEL}
        />
        <WebhookSidePanel />
      </div>
    </FlowBuilderContext.Provider>
  );
}

function FlowWithProvider() {
  return (
    <ReactFlowProvider>
      <FlowBuilder />
    </ReactFlowProvider>
  );
}

export default FlowWithProvider;
