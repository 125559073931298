import BaseAPI from './base.api';
import { LanguageResponse } from 'interface/settings/settings.interface';

interface ConfigName {
  configName: string;
}

export interface ObjectForSettingsApi {
  config?: string;
  configName: ConfigName;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  settings?: any;
}

export default class SettingAPI extends BaseAPI {
  private ROUTES = {
    allLanguages: 'sso/settings/configs/languages',
    allCategories: 'sso/settings/configs/point-sol-categories',
    allSidepanels: 'sso/settings/configs/point-sol-sidepanel',
    theme: 'sso/settings/configs/es-2-theme',
    project: 'kernel/projects',
    createRoute: 'sso/settings/configs',
    experienceflows: 'sso/settings/configs/experience-flows',
    merchantPortal: 'sso/settings/configs/merchant-portal',
  };

  getLanguages(): Promise<LanguageResponse> {
    return this.GET(this.ROUTES.allLanguages);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getCategories(): Promise<any> {
    return this.GET(this.ROUTES.allCategories);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getSidePanelSettings(): Promise<any> {
    return this.GET(this.ROUTES.allSidepanels);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getProjectSettings(projectId?: string): Promise<any> {
    return this.GET(this.ROUTES.project + '/' + projectId);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getThemeSettings(): Promise<any> {
    return this.GET(this.ROUTES.theme);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createSettings(settingsObject: ObjectForSettingsApi): Promise<any> {
    return this.POST(this.ROUTES.createRoute, settingsObject);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getExperienceWorkflows(): Promise<any> {
    return this.GET(this.ROUTES.experienceflows);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getMerchantPortalSettings(): Promise<any> {
    return this.GET(this.ROUTES.merchantPortal);
  }
}
