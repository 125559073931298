import BaseAPI from 'services/api/base.api';
import {
  AnalyticsOverview,
  ContentsEngagementResponse,
  ContextRulesEngagementResponse,
  EngagementParams,
  PushNotificationsEngagementResponse,
  PushNotificationResponse,
  JourneyEngagementParams,
  JourneysEngagementResponse,
} from 'components/Analytics/types';
import { Content, ContentTemplate } from 'interface/content/content.interface';
import { JourneyRulePayload } from 'interface/rule/rule.interface';

export default class AnalyticsExperiencesAPI extends BaseAPI {
  private ROUTES = {
    OVERVIEW_EXPERIENCE: '/analytics/analytics/overview',
    CONTENTS_ENGAGEMENT: '/analytics/analytics/content',
    CONTEXT_RULES_ENGAGEMENT: '/analytics/analytics/context/rules',
    PUSH_NOTIFICATIONS_ENGAGEMENT: '/analytics/analytics/engagement/push',
    JOURNEYS_ENGAGEMENT: '/analytics/analytics/journeys/engagement',
    CONTENT: '/kernel/content/instances',
    CONTEXT_RULE: '/context/rules',
    PUSH_NOTIFICATION: '/push/pushrequests',
    TEMPLATE: '/kernel/content/templates',
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getOverviewExperiences(opts: any): Promise<AnalyticsOverview> {
    let params = {
      totalUsers: true,
      pushOptIn: true,
      experiencesLaunched: true,
      avgEngagementRate: true,
      pushOpenRate: true,
      clickThroughRate: true,
      totalDevices: true,
      locationOptIn: true,
    };
    params = Object.assign(params, opts);
    return this.GET(this.ROUTES.OVERVIEW_EXPERIENCE, params);
  }

  getPushNotificationsEngagement(opts: EngagementParams): Promise<PushNotificationsEngagementResponse> {
    const params = {
      limit: 5,
      offset: 0,
      search: null,
    };
    Object.assign(params, opts);
    return this.GET(this.ROUTES.PUSH_NOTIFICATIONS_ENGAGEMENT, params);
  }

  getContextRulesEngagement(opts: EngagementParams): Promise<ContextRulesEngagementResponse> {
    const params = {
      limit: 5,
      offset: 0,
      search: null,
    };
    Object.assign(params, opts);
    return this.GET(this.ROUTES.CONTEXT_RULES_ENGAGEMENT, params);
  }

  getContentsEngagement(opts: EngagementParams): Promise<ContentsEngagementResponse> {
    const params = {
      limit: 5,
      offset: 0,
      search: null,
    };
    Object.assign(params, opts);
    return this.GET(this.ROUTES.CONTENTS_ENGAGEMENT, params);
  }
  getJourneysEngagement(opts: JourneyEngagementParams): Promise<JourneysEngagementResponse> {
    const params = {
      limit: 5,
      offset: 0,
      search: null,
    };
    Object.assign(params, opts);
    return this.GET(this.ROUTES.JOURNEYS_ENGAGEMENT, params);
  }

  getPushNotification(pushId: string): Promise<PushNotificationResponse> {
    return this.GET(`${this.ROUTES.PUSH_NOTIFICATION}/${pushId}`);
  }

  getContent(contentId: string): Promise<Content> {
    return this.GET(`${this.ROUTES.CONTENT}/${contentId}`, {
      bypassAdmin: true,
      managementMode: true,
      disableContextEmbed: true,
      data: true,
    });
  }

  getTemplate(templateId: string): Promise<ContentTemplate> {
    return this.GET(`${this.ROUTES.TEMPLATE}/${templateId}`);
  }

  getContextRule(contextRuleId: string): Promise<JourneyRulePayload> {
    return this.GET(`${this.ROUTES.CONTEXT_RULE}/${contextRuleId}`);
  }
}
