/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useThunkDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/reduxHooks';
import Storage from 'services/storage';
import moment from 'moment';
import queryString from 'query-string';

// Redux Actions
import useActions from 'hooks/useActions';
import * as TExperienceActions from 'store/templated-experience/templated-experience.action';

import { ERROR_TYPES } from 'pages/ExperienceCanvas/types';
import { DropdownOptions } from 'interface/shared/dropdown.interface';
import {
  FlightButton,
  FlightTable,
  FlightOverflowMenu,
  FlightTag,
  FlightTooltip,
  FlightTextInput,
  FlightSelectMulti,
  getIcon,
} from '@flybits/design-system';
import './TemplatedExperienceIndex.scss';
import { defineStatusStyle, convertTimezoneToDate, getTenantId } from 'helpers/templated-experience.helper';
import ConfirmModal from 'components/Modal/ConfirmModal/ConfirmModal';
import { trackEvent } from 'helpers/analytics.helper';
import GroupIcon from 'assets/icons/four-squares.svg';
import EmptyStateImage from 'assets/images/experiences-empty-state.png';
import useFeatureFlag from 'hooks/useFeatureFlag';
import GroupAPI from 'services/api/group.api';
import LabelsAPI from 'services/api/labels.api';
import { Group } from 'interface/group.interface';
import useDebounce from 'hooks/useDebounce';
import { isEmpty, isEqual, union, reduce, keys, cloneDeep } from 'lodash';
import { ActionTypes } from 'store/actionTypes';
import { Status, Label, Type, Experience } from 'interface/experience/experience.interface';
import LabelsModal from 'components/Modal/LabelsModal/LabelsModal';
import useApprovalSettings from 'hooks/useApprovalSettings';
import { JOURNEY_STATUSES } from 'store/journey/journey.type';
import DeactivateIcon from 'pages/ExperienceCanvas/assets/icons/DeactivateIcon';
import LaunchIcon from 'pages/ExperienceCanvas/assets/icons/LaunchIcon';
import TemplatedExperienceAPI from 'services/api/templated-experience.api';
import { useQueryClient } from '@tanstack/react-query';

const deactivateIconFill = '#FF541E';
const activateIconFill = '#2371F1';

const MAIN_CLASS = 'templated-experiences-index';
const CLASSES = {
  EMPTY_STATE: `${MAIN_CLASS}__empty-state__container`,
  EMPTY_STATE_PRIMARY_TEXT: `${MAIN_CLASS}__empty-state__text--primary`,
  EMPTY_STATE_SECONDARY_TEXT: `${MAIN_CLASS}__empty-state__text--secondary`,
};

const EmptyState = ({ cta }: { cta: () => void }) => {
  return (
    <div className={CLASSES.EMPTY_STATE}>
      <img src={EmptyStateImage} alt="empty state" />
      <h3>Get Started with Experiences</h3>
      <p className={CLASSES.EMPTY_STATE_SECONDARY_TEXT}>Currently, there are no experiences crafted yet.</p>
      <p className={CLASSES.EMPTY_STATE_PRIMARY_TEXT}>
        Create your very first experience now and unlock the full potential of our platform.
      </p>
      <FlightButton label="Create an experience" size="medium" onClick={cta} />
    </div>
  );
};

export default function TemplatedExperienceIndex() {
  const groupAPI = useMemo(() => new GroupAPI(), []);
  const labelsAPI = useMemo(() => new LabelsAPI(), []);
  const storage = useMemo(() => new Storage(), []);
  const templatedExperienceAPI = useMemo(() => new TemplatedExperienceAPI(), []);
  const queryClient = useQueryClient();
  const { flags } = useFeatureFlag();
  const history = useHistory();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [groupsState, setGroupsState] = useState<Group[]>([]);
  const [selectedExperiences, setSelectedExperiences] = useState<any>(new Set([]));
  const [maxPage, setMaxPage] = useState<number>(0);
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);
  const reduxTemplatedExperienceState = useSelector((state) => state.templatedExperience);
  const queryParams = queryString.parse(history.location?.search);
  const [filters, setFilters] = useState<any>({
    search: queryParams.search! ? String(queryParams.search) : '',
    searchExact: queryParams.searchExact! === 'true' ? Boolean(queryParams.searchExact) : false,
    sortby: queryParams.sortby! ? String(queryParams.sortby) : 'createdAt',
    sortorder: queryParams.sortorder! ? String(queryParams.sortorder) : 'desc',
    limit: queryParams.limit! ? Number(queryParams.limit) : 10,
    page: queryParams.page! ? Number(queryParams.page) : 1,
    status: !isEmpty(queryParams.status) ? queryParams.status : [],
    type: !isEmpty(queryParams.type) ? queryParams.type : [],
    label: !isEmpty(queryParams.label) ? queryParams.label : [],
    startdate: queryParams.startdate! ? Number(queryParams.startdate) : 0,
    enddate: queryParams.enddate! ? Number(queryParams.enddate) : 0,
  });
  const initialFilters: any = {
    search: '',
    searchExact: false,
    sortby: 'createdAt',
    sortorder: 'desc',
    limit: 10,
    page: 1,
    status: [],
    type: [],
    label: [],
    startdate: 0,
    enddate: 0,
  };
  const [activeFilters, setActiveFilters] = useState(0);
  const debouncedFilters = useDebounce(filters, 300);
  const [showFilters, setShowFilters] = useState(false);
  const initialModalProps = {
    isVisible: false,
    icon: 'warning.svg',
    title: '',
    content: <></>,
    contentIcon: <></>,
    errorMessage: '',
    primaryButton: {
      name: 'Confirm',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: () => {},
      disabled: false,
    },
    cancelButton: {
      name: 'Cancel',
      onClick: () => setConfirmModalProps((props) => ({ ...props, isVisible: false })),
    },
  };
  const [confirmModalProps, setConfirmModalProps] = useState(initialModalProps);
  const [labelsModalProps, setLabelsModalProps] = useState({});
  const [tableData, setTableData] = useState<any[]>();

  const { settings: approvalSettings } = useApprovalSettings();
  // fallback value set to false in case the actual value fails to retrieve from the backend
  const approvalRequired = approvalSettings?.approvalRequired ?? false;
  const isJourneyApprovalRequired = flags['tx_maker_checker'] || approvalRequired;

  const tableHeader = [
    {
      name: 'Name',
      key: 'name',
      hideTooltip: true,
      isVisible: true,
      isSortable: true,
    },
    {
      name: 'Status',
      key: 'status',
      hideTooltip: true,
      isVisible: true,
      isSortable: false,
    },
    {
      name: 'Schedule',
      key: 'schedule',
      hideTooltip: true,
      isVisible: true,
      isSortable: false,
    },
    {
      name: 'Last modified',
      key: 'updatedAt',
      hideTooltip: true,
      isVisible: true,
      isSortable: true,
    },
    {
      name: 'Date created',
      key: 'createdAt',
      hideTooltip: true,
      isVisible: true,
      isSortable: true,
    },
    {
      name: 'Options',
      key: 'edit',
      isVisible: true,
      hideTooltip: true,
      isSortable: false,
    },
    {
      name: '',
      key: 'item',
      hideTooltip: true,
      isVisible: false,
      isSortable: false,
    },
  ];
  if (flags['tx_labels']) {
    tableHeader.splice(2, 0, {
      name: 'Labels',
      key: 'labels',
      hideTooltip: true,
      isVisible: true,
      isSortable: false,
    });
  }
  if (isJourneyApprovalRequired) {
    tableHeader.splice(-2, 0, {
      name: 'Approval',
      key: 'approval',
      hideTooltip: true,
      isVisible: true,
      isSortable: false,
    });
  }

  const initialStatuses = [
    {
      key: 'active',
      name: 'Active',
      isSelected: false,
    },
    {
      key: 'draft',
      name: 'Draft',
      isSelected: false,
    },
    {
      key: 'inactive',
      name: 'Inactive',
      isSelected: false,
    },
    {
      key: 'scheduled',
      name: 'Scheduled',
      isSelected: false,
    },
  ];
  const [statuses, setStatuses] = useState<Status[]>(cloneDeep(initialStatuses));
  const [labels, setLabels] = useState<Label[]>([]);
  const [, setAllLabels] = useState<Label[]>([]);
  const [templateTypes, setTemplateTypes] = useState<Type[]>([]);

  const pathName = history.location.pathname.replace(/\/$/, '');

  useEffect(() => {
    document.title = 'Experiences | Experience Studio @ Flybits';
  }, []);

  useEffect(() => {
    dispatch({
      type: ActionTypes.CLEAR_JOURNEY,
    });
  }, [dispatch]);

  useEffect(() => {
    const filterButton = document.getElementsByClassName('templated-experiences-index__filters__button')[0];
    filterButton?.setAttribute('aria-expanded', showFilters.toString());
  }, [showFilters]);

  useEffect(() => {
    if (flags['tx_groups']) {
      groupAPI.getGroups(0, 10000, '').then((res: any) => {
        setGroupsState(res.data);
      });
    }
    if (flags['tx_labels']) {
      labelsAPI.getLabels('').then((res: any) => {
        const serializedLabels = res?.data?.sort()?.map((label: string) => ({
          key: label,
          name: label,
          isSelected: filters?.label?.includes(label) || false,
        }));
        setLabels(serializedLabels);
        setAllLabels(serializedLabels);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initial Load
  useEffect(() => {
    reduxTemplatedExperienceAction.fetchInstances(filters);
    history.push(`${pathName}?${queryString.stringify({ ...filters }, { arrayFormat: 'none' })}`);
    if (!isEmpty(filters.status)) {
      const selectedStatuses = statuses?.map((status) => {
        if (filters?.status?.includes(status.key)) {
          status.isSelected = true;
        } else {
          status.isSelected = false;
        }
        return status;
      });
      setStatuses(selectedStatuses);
      setShowFilters(true);
    }
    if (!isEmpty(filters.type)) {
      const selectedTypes = templateTypes?.map((type) => {
        if (filters?.type?.includes(type.key)) {
          type.isSelected! = true;
        } else {
          type.isSelected = false;
        }
        return type;
      });
      setTemplateTypes(selectedTypes);
      setShowFilters(true);
    }
    if (!isEmpty(filters.label)) {
      const selectedLabels = labels?.map((label) => {
        if (filters?.label?.includes(label.key)) {
          label.isSelected! = true;
        } else {
          label.isSelected! = false;
        }
        return label;
      });
      setLabels(selectedLabels);
      setShowFilters(true);
    }
    if (filters.startdate || filters.enddate) {
      setShowFilters(true);
    }
    setActiveFilters(countActiveFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilters]);

  // Re-render on state change
  useEffect(() => {
    const renderDropdown = (status: string, data: Experience) => {
      const optionGroups: Array<Array<DropdownOptions>> = [
        [
          {
            key: 2,
            name: 'Duplicate',
            disabled: false,
            onClick: () => {
              history.push(
                `/project/${projectId}/experiences/${data.templateId}/overview?${queryString.stringify({
                  ...filters,
                  createFromId: data.id,
                })}`,
              );
            },
          },
          {
            key: 3,
            name: 'Delete',
            disabled: data.status === JOURNEY_STATUSES.ACTIVE || data.status === JOURNEY_STATUSES.SCHEDULED,
            onClick: () => onDelete(data),
          },
        ],
      ];

      if (flags['tx_enable_flow']) {
        optionGroups[0].unshift({
          key: 1,
          name: 'View Flow',
          disabled: false,
          onClick: () => {
            let urlQueryString = window.location.search;
            if (urlQueryString) {
              urlQueryString += '&viewFlow=true';
            } else {
              urlQueryString = '?viewFlow=true';
            }

            history.push(`/project/${projectId}/experiences/${data.id}/flow-builder${urlQueryString}`);
          },
        });
      }

      if (isJourneyApprovalRequired && data.isApproved !== undefined) {
        optionGroups[0].unshift({
          key: 4,
          name: 'View Request',
          disabled: false,
          onClick: () => {
            history.push(`/project/${projectId}/experiences/${data.id}/approval`);
          },
        });
      }

      if (status === JOURNEY_STATUSES.INACTIVE) {
        optionGroups[0].push({
          key: 5,
          name: `Activate`,
          disabled: false,
          onClick: () => {
            setConfirmModalProps((props) => ({
              ...props,
              isVisible: true,
              title: 'Activate Experience',
              icon: '',
              contentIcon: <LaunchIcon fill={activateIconFill} />,
              content: (
                <p>
                  This experience will save as <strong>Active</strong> and will become visible to your users.
                </p>
              ),
              primaryButton: {
                name: 'Confirm',
                disabled: false,
                onClick: async () => {
                  setConfirmModalProps((props) => ({ ...props, isLoading: true }));
                  await handleActivateInstance(data.id);
                  await queryClient.invalidateQueries(['active-contents']);
                  setConfirmModalProps((props) => ({ ...props, isLoading: false, isVisible: false }));
                },
              },
              cancelButton: {
                name: 'Cancel',
                onClick: () => {
                  setConfirmModalProps((props) => ({ ...props, isVisible: false }));
                },
              },
            }));
          },
        });
      } else if (status === JOURNEY_STATUSES.ACTIVE || status === JOURNEY_STATUSES.SCHEDULED) {
        optionGroups[0].push({
          key: 5,
          name: `Deactivate`,
          disabled: false,
          onClick: () => {
            setConfirmModalProps((props) => ({
              ...props,
              isVisible: true,
              title: 'Deactivate Experience',
              icon: '',
              contentIcon: <DeactivateIcon fill={deactivateIconFill} />,
              content: (
                <>
                  <p>You are about to deactivate your experience immediately.</p>
                  <p>Once deactivated, it will no longer be visible to your customers according to the schedule.</p>
                  <p>Are you sure you would like you deactivate it?</p>
                </>
              ),
              primaryButton: {
                name: 'Confirm',
                disabled: false,
                onClick: async () => {
                  setConfirmModalProps((props) => ({ ...props, isLoading: true }));
                  await handleDeactivateInstance(data.id);
                  await queryClient.invalidateQueries(['active-contents']);
                  setConfirmModalProps((props) => ({ ...props, isLoading: false, isVisible: false }));
                },
              },
              cancelButton: {
                name: 'Cancel',
                onClick: () => {
                  setConfirmModalProps((props) => ({ ...props, isVisible: false }));
                },
              },
            }));
          },
        });
      }

      return (
        <FlightOverflowMenu
          className={'templated-experiences-index__dropdown'}
          direction={'bottom'}
          isOpenRight={false}
          disabled={false}
          optionGroups={optionGroups}
        />
      );
    };

    if (reduxTemplatedExperienceState.instances?.data) {
      const pagination = reduxTemplatedExperienceState.instances.pagination;
      const maxPage = Math.ceil(pagination.totalRecords / Number(filters.limit));
      setMaxPage(maxPage >= Number(filters.page) ? maxPage : Number(filters.page));
      const _tableData = reduxTemplatedExperienceState?.instances?.data?.map((data: Experience) => {
        let startDate = data.schedule?.start;
        const instanceStatus = data.status?.toLowerCase() || '';
        if (instanceStatus === 'inactive' || instanceStatus === 'active') {
          if (data.activatedAt && startDate! < data.activatedAt!) {
            startDate = data.activatedAt;
          }
        }
        let endDate = data.schedule?.end;
        if (instanceStatus === 'inactive') {
          if (data.deactivatedAt && endDate! < data.activatedAt!) {
            endDate = data.deactivatedAt;
          }
        }
        const timezone = data?.schedule?.timezone ? data.schedule.timezone : moment.tz.guess().replace(/_/g, ' ');
        let local_start_desc = null;
        let local_end_desc = null;
        let scheduleDescr = null;
        let schedule = (
          <FlightTooltip
            className="custom-class-name"
            direction="top"
            isEnabled={true}
            description={'None set'}
            delay={0}
          >
            None set
          </FlightTooltip>
        );

        if (startDate) {
          const convertStart = convertTimezoneToDate(startDate, timezone);
          if (convertStart.local_date + convertStart.local_time !== convertStart.tz_date + convertStart.tz_time) {
            local_start_desc = (
              <span className="templated-experiences-index__user-timezone">
                <hr /> Your local time: {convertStart.local_time}, {convertStart.local_date}
              </span>
            );
          }
          schedule = (
            <FlightTooltip
              className="templated-experiences-index__tooltip"
              direction="top"
              isEnabled={true}
              description={
                <div>
                  {(instanceStatus === 'active' || instanceStatus === 'inactive') &&
                  data.schedule?.start! <= data.activatedAt!
                    ? 'Launched '
                    : instanceStatus !== 'draft'
                    ? 'Starts '
                    : 'Schedule is not in effect because experience is in draft state. Start '}
                  at {convertStart.tz_time}, {convertStart.tz_date}
                  <br />
                  Time zone: {timezone}
                  {local_start_desc}
                </div>
              }
              delay={0}
            >
              {convertStart.tz_date}
            </FlightTooltip>
          );
          if (instanceStatus === 'active' || instanceStatus === 'scheduled') {
            scheduleDescr = (
              <div className={'templated-experiences-index__shedule-descr'}>
                Starts in {moment(data.schedule?.start! * 1000).fromNow(true)}
              </div>
            );
            if (data.schedule?.start! < moment().unix() && instanceStatus === 'active') {
              scheduleDescr = (
                <div className={'templated-experiences-index__shedule-descr'}>
                  Automatically launched {moment(data.schedule?.start! * 1000).fromNow(true)} ago
                </div>
              );
            }
          }
        }

        if (endDate) {
          const convertEnd = convertTimezoneToDate(endDate, timezone);
          if (convertEnd.local_date + convertEnd.local_time !== convertEnd.tz_date + convertEnd.tz_time) {
            local_end_desc = (
              <span className="templated-experiences-index__user-timezone">
                <hr />
                Your local time: {convertEnd.local_time}, {convertEnd.local_date}
              </span>
            );
          }
          schedule = (
            <div>
              {schedule} –{' '}
              <FlightTooltip
                className="custom-class-name"
                direction="top"
                isEnabled={true}
                description={
                  <div>
                    {instanceStatus === 'inactive'
                      ? data.deactivatedAt! && !data.schedule?.end
                        ? 'Deactivated '
                        : 'Schedule ended '
                      : instanceStatus !== 'draft'
                      ? 'Ends '
                      : 'Schedule is not in effect because experience is in draft state. End '}
                    at {convertEnd.tz_time}, {convertEnd.tz_date}
                    <br />
                    Time zone: {timezone}
                    {local_end_desc}
                  </div>
                }
                delay={0}
              >
                {convertEnd.tz_date}
              </FlightTooltip>
            </div>
          );
          if ((instanceStatus === 'active' || data.schedule?.start! < moment().unix()) && instanceStatus !== 'draft') {
            let endText = 'No end date';
            if (data.schedule?.end! > moment().unix()) {
              endText = 'Ends in ' + moment(data.schedule?.end! * 1000).fromNow(true);
            }
            if (data.schedule?.end! < moment().unix()) {
              endText = 'Ended ' + moment(data.schedule?.end! * 1000).fromNow(true) + ' ago';
            }
            scheduleDescr = <div className={'templated-experiences-index__shedule-descr'}>{endText}</div>;
          }
        } else {
          if (startDate) {
            schedule = (
              <div>
                {schedule} –{' '}
                <FlightTooltip
                  className="custom-class-name"
                  direction="top"
                  isEnabled={true}
                  description={`No end date`}
                  delay={0}
                >
                  No end date
                </FlightTooltip>
              </div>
            );
          }
        }

        if (instanceStatus === 'active' && !data.schedule?.end) {
          scheduleDescr = (
            <div className={'templated-experiences-index__shedule-descr'}>
              Launched on {moment((data?.activatedAt ?? 0) * 1000).format('MMM D, YYYY')}
            </div>
          );
        }
        if (instanceStatus === 'inactive' && data?.deactivatedAt) {
          scheduleDescr = (
            <div className={'templated-experiences-index__shedule-descr'}>
              Deactivated on {moment((data?.deactivatedAt ?? 0) * 1000).format('MMM D, YYYY')}
            </div>
          );
        }

        let tableCells: any = {
          key: data.id,
          name: (
            <div className="templated-experiences-index__name">
              <div className="templated-experiences-index__instance-name" title={data.name || 'Untitled'}>
                {flags['tx_groups'] && data.metadata?.templateType === '' && !!data.steps?.[0]?.groups?.length && (
                  <FlightTooltip
                    direction="right"
                    isEnabled={true}
                    description={
                      <div>
                        This experience is part of the group(s):
                        <br />
                        {data.steps?.[0]?.groups?.map((group: any, idx: number) => {
                          let separator = '';
                          if (idx < data.steps?.[0]?.groups!.length - 1) separator = ', ';
                          const groupData = groupsState?.find((g) => g.id === group.id);
                          return groupData?.localizations?.en?.name + separator;
                        })}
                      </div>
                    }
                  >
                    <img src={GroupIcon} alt={''} className="templated-experiences-index__icon" />
                  </FlightTooltip>
                )}
                {data.name || 'Untitled'} <br />
                <div className={'templated-experiences-index__instance-template-type'}>
                  {data.metadata?.templateName || ''}
                </div>
              </div>
            </div>
          ),
          status: instanceStatus && (
            <div>
              <FlightTag
                type={defineStatusStyle(instanceStatus)}
                label={instanceStatus}
                className="templated-experiences-index__status"
              />
            </div>
          ),
          schedule: (
            <div>
              {schedule}
              {data.status !== 'Draft' ? scheduleDescr : ''}
            </div>
          ),
          updatedAt: moment(data.updatedAt! * 1000).format('MMM D, YYYY'),
          createdAt: moment(data.createdAt! * 1000).format('MMM D, YYYY'),
          edit: renderDropdown(instanceStatus, data),
          item: data,
        };

        let extraLabels = '';
        if (flags['tx_labels']) {
          tableCells = {
            ...tableCells,
            labels: (
              <div className="templated-experiences-index__labels">
                {data.labels?.map((label: string, idx: number) => {
                  if (idx < 2) {
                    return (
                      <span key={label} className="templated-experiences-index__labels__label">
                        {label}
                      </span>
                    );
                  } else {
                    extraLabels += `${label}${data.labels?.length! - 1 > idx ? ',' : ''} `;
                    return null;
                  }
                })}
                {data.labels?.length! > 2 && (
                  <FlightTooltip direction="top" isEnabled={true} description={extraLabels} delay={0}>
                    <span className="templated-experiences-index__labels__text">+{data.labels!.length - 2} more</span>
                  </FlightTooltip>
                )}
              </div>
            ),
          };
        }
        if (isJourneyApprovalRequired) {
          const approvalClass = 'templated-experiences-index__approvals';
          const approvalStatus =
            data.isApproved === true
              ? {
                  icon: getIcon('checkCircleOutline', {}),
                  label: 'Approved',
                  class: `${approvalClass}--approved`,
                }
              : data.isApproved === false
              ? {
                  icon: getIcon('education', {}),
                  label: 'In Review',
                  class: `${approvalClass}--review`,
                }
              : {
                  icon: null,
                  label: 'N/A',
                  class: `${approvalClass}--na`,
                };
          tableCells = {
            ...tableCells,
            approval: (
              <div className={`${approvalClass} ${approvalStatus.class}`}>
                {approvalStatus.icon}
                {approvalStatus.label}
              </div>
            ),
          };
        }

        return tableCells;
      });

      setTableData(_tableData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxTemplatedExperienceState.instances, filters, history, groupsState]);

  const onPageChange = (page: number) => {
    if (filters.page !== page) {
      setFilters({
        ...filters,
        page,
      });
    }
  };

  const onRowPerPageChange = (rowPerPage: number) => {
    if (filters.limit !== rowPerPage) {
      const pagination = reduxTemplatedExperienceState.instances.pagination;
      const _currentPage = rowPerPage * Number(filters.page) > pagination.totalRecords ? 1 : Number(filters.page);
      setFilters({
        ...filters,
        page: _currentPage,
        limit: rowPerPage,
      });
    }
  };

  const onGoToES1 = async () => {
    const tenantId = getTenantId();
    const origin = await storage.getItem(`${tenantId}+origin`);
    const token = await storage.getItem(`${tenantId}+token`);
    if (origin) {
      trackEvent({
        category: 'Templated Experiences',
        action: 'Return to Experience Studio (Ember)',
      });
      window.location.href = `${origin}/#/authenticate?sourceRouteName=experiences&token=${token}`;
    } else {
      alert(
        'Temporary Warning: Could not find Ember Route to redirect to. Please enter this route from ember or by using the developer hub.',
      );
      history.push('/developer-hub');
    }
  };

  const onDelete = (instance: Experience) => {
    setConfirmModalProps((props) => ({
      ...props,
      isVisible: true,
      title: 'Delete Experience',
      content: (
        <p>
          Deleting is permanent and can not be undone. Any Active and Scheduled experiences will not be shown to
          customers.
        </p>
      ),
      primaryButton: {
        name: 'Delete Experience',
        disabled: false,
        onClick: () => onDeleteConfirm(instance),
      },
    }));
  };

  const onDeleteConfirm = (instance: Experience) => {
    reduxTemplatedExperienceAction
      .deleteTemplatedExperienceInstance(instance, filters)
      .then(() => {
        setConfirmModalProps((props) => ({
          ...props,
          isVisible: false,
        }));
        trackEvent({
          category: 'Templated Experiences',
          action: 'Delete',
        });
      })
      .catch(() => {
        setConfirmModalProps((props) => ({
          ...props,
          isVisible: false,
        }));
        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: { content: 'Something went wrong while deleting the experience', type: ERROR_TYPES.ERROR },
        });
      });
  };

  type TRouteParams = {
    pid: string;
  };
  const { pid: projectId } = useRouteMatch<TRouteParams>().params;

  const handleDataSelect = (e: any) => {
    trackEvent({
      category: 'Templated Experiences',
      action: 'View',
    });

    if (e.item?.steps?.[0]?.audience) {
      // if instance
      setFilters({ ...filters });

      return history.push(
        flags['tx_enable_flow']
          ? `/project/${projectId}/experiences/${e.item.id}/flow-builder?${queryString.stringify({ ...filters })}`
          : `/project/${projectId}/experiences/${e.item.id}/overview?${queryString.stringify({ ...filters })}`,
      );
    }
  };

  const handleCheckboxMultiSelect = (e: any) => {
    if (selectedExperiences.has(e.key)) {
      selectedExperiences.delete(e.key);
    } else {
      selectedExperiences.add(e.key);
    }
    setSelectedExperiences(new Set(selectedExperiences));
  };

  const handleSelectAll = (e: any) => {
    setSelectedExperiences(e);
  };

  const handleCreateExperience = () => {
    history.push(`${pathName}/experiences/templates/library/?${queryString.stringify({ ...filters })}`);
  };

  const updateSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({
      ...filters,
      page: 1,
      search: e.target.value,
    });
  };

  const handleHeaderSort = (e: { key: string }) => {
    setFilters({
      ...filters,
      sortby: e.key,
      sortorder: filters.sortorder === 'desc' ? 'asc' : 'desc',
    });
  };

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleStatusSelect = (e: Status) => {
    const updatedStatuses = statuses.map((status) => {
      if (status.key === e.key) {
        status.isSelected = !status.isSelected;
      }
      return status;
    });
    setStatuses(updatedStatuses);
    const selectedStatuses: any = [];
    updatedStatuses.forEach((status) => {
      if (status.isSelected) {
        selectedStatuses.push(status.key);
      }
    });
    setFilters({
      ...filters,
      status: selectedStatuses,
    });
  };

  const countActiveFilters = () => {
    const allkeys = union(keys(filters), keys(initialFilters));
    const difference = reduce(
      allkeys,
      function (result: any, key: string) {
        if (!isEqual(filters[key], initialFilters[key])) {
          if (key === 'status' || key === 'label' || key === 'enddate' || key === 'type') {
            result[key] = { obj1: filters[key], obj2: initialFilters[key] };
          }
        }
        return result;
      },
      {},
    );
    return keys(difference)?.length || 0;
  };

  const clearFilters = () => {
    setFilters(initialFilters);
    const clearedStatuses = statuses.map((status) => {
      status.isSelected = false;
      return status;
    });
    setStatuses(clearedStatuses);
    const clearedLabels = labels.map((label) => {
      label.isSelected = false;
      return label;
    });
    setLabels(clearedLabels);
    const clearedTypes = templateTypes.map((type) => {
      type.isSelected = false;
      return type;
    });
    setTemplateTypes(clearedTypes);
  };

  const handleAddLabels = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setLabelsModalProps({
      ...labelsModalProps,
      isVisible: true,
      entities: selectedExperiences,
      onCancel: () => setLabelsModalProps({ ...labelsModalProps, isVisible: false }),
    });
  };

  const handleBulkDelete = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setConfirmModalProps((props) => ({
      ...props,
      isVisible: true,
      title: 'Delete',
      content: (
        <>
          <div style={{ marginBottom: '20px' }}>The selected Experience(s) will be permanently deleted.</div>
          <div>This cannot be undone. Do you wish to continue?</div>
        </>
      ),
      primaryButton: {
        name: 'Confirm',
        disabled: false,
        onClick: onBulkDeleteConfirm,
      },
      cancelButton: {
        name: 'Cancel',
        onClick: () => setConfirmModalProps((props) => ({ ...props, isVisible: false, errorMessage: '' })),
      },
    }));
  };

  const onBulkDeleteConfirm = async () => {
    const experienceIdsToDelete: string[] = [];

    if (!isEmpty(selectedExperiences)) {
      selectedExperiences.forEach((child: string) => {
        experienceIdsToDelete.push(child);
      });
      reduxTemplatedExperienceAction
        .bulkDeleteInstances(experienceIdsToDelete)
        .then(() => {
          setSelectedExperiences(new Set([]));
          setFilters({ ...filters });
          setConfirmModalProps((props) => ({
            ...props,
            isVisible: false,
          }));
        })
        .catch((error: any) => {
          setConfirmModalProps((props) => ({
            ...props,
            isVisible: false,
          }));
          dispatch({
            type: 'SHOW_SNACKBAR',
            payload: {
              content: error?.message ?? 'An unknown error occurred during bulk deletion',
              type: 'error',
            },
          });
        });
    }
  };

  const handleActivateInstance = useCallback(
    async (id: string) => {
      try {
        const res = await templatedExperienceAPI.activateTemplatedExperienceInstance(id);
        if (res.status?.toLowerCase() === JOURNEY_STATUSES.ACTIVE) {
          dispatch({
            type: 'SHOW_SNACKBAR',
            payload: {
              content: `The experience has been ACTIVATED`,
              type: 'default',
            },
          });
          reduxTemplatedExperienceAction.fetchInstances(filters);
        }
      } catch (err: any) {
        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: {
            content: err?.response?.data ?? err?.message ?? 'An unknown error occurred during activation',
            type: 'error',
          },
        });
      }
    },
    [dispatch, templatedExperienceAPI, reduxTemplatedExperienceAction, filters],
  );

  const handleDeactivateInstance = useCallback(
    async (id: string) => {
      try {
        const res = await templatedExperienceAPI.deactivateTemplatedExperienceInstance(id);
        if (res.status?.toLowerCase() === JOURNEY_STATUSES.INACTIVE) {
          dispatch({
            type: 'SHOW_SNACKBAR',
            payload: {
              content: `The experience has been DEACTIVATED`,
              type: 'default',
            },
          });
          reduxTemplatedExperienceAction.fetchInstances(filters);
        }
      } catch (err: any) {
        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: {
            content: err?.response?.data ?? err?.message ?? 'An unknown error occurred during deactivation',
            type: 'error',
          },
        });
      }
    },
    [dispatch, templatedExperienceAPI, reduxTemplatedExperienceAction, filters],
  );

  return (
    <div className="templated-experiences-index">
      <div className="templated-experiences-index__container">
        {flags['tx_es1_link_visible'] && (
          <div className="templated-experiences-index__es1">
            Looking for<button onClick={onGoToES1}>Experience Studio 1.0</button>?
          </div>
        )}

        <div className="templated-experiences-index__header">
          <div>
            <div className="templated-experiences-index__title">Experiences</div>
          </div>
          {!!tableData?.length && !reduxTemplatedExperienceState.isLoading && (
            <div className="templated-experiences-index__buttons-container">
              <FlightButton
                label="New experience"
                size="large"
                className="templated-experiences-index__create-button"
                onClick={handleCreateExperience}
              />
            </div>
          )}
        </div>
        {errorMessage && <div className="templated-experiences-index__error-message">Error: {errorMessage}</div>}
        {(!!tableData?.length || reduxTemplatedExperienceState.isLoading) && (
          <>
            {flags['tx_filters'] && (
              <>
                <div className="templated-experiences-index__filters">
                  <div className="templated-experiences-index__filters__search-container">
                    <FlightTextInput
                      width="100%"
                      iconInput="search"
                      value={String(filters.search)}
                      hasClearIcon={true}
                      onChange={updateSearchText}
                      className="templated-experiences-index__filters__search"
                      label="Search"
                    />
                    <FlightButton
                      label={
                        <span>
                          Filters{' '}
                          {activeFilters > 0 ? (
                            <strong className="templated-experiences-index__filters__button__label">
                              {activeFilters}
                            </strong>
                          ) : (
                            ''
                          )}
                        </span>
                      }
                      size="medium"
                      className={`templated-experiences-index__filters__button ${
                        activeFilters > 0 || showFilters ? 'active' : ''
                      }`}
                      onClick={handleShowFilters}
                      theme="secondary"
                      iconRight={showFilters ? 'baselineKeyboardArrowUp' : 'baselineKeyboardArrowDown'}
                    />
                    {!!countActiveFilters() && (
                      <FlightButton
                        label="Clear all"
                        size="medium"
                        className="templated-experiences-index__filters__button-clear"
                        onClick={clearFilters}
                        theme="link"
                      />
                    )}
                  </div>
                </div>
                {showFilters && (
                  <div className="templated-experiences-index__filters">
                    <div className="templated-experiences-index__filters__search-container">
                      <FlightSelectMulti
                        label="Status"
                        isLabelAlwaysShown={true}
                        className=""
                        options={statuses}
                        handleOptionSelect={handleStatusSelect}
                      />
                      <div className="templated-experiences-index__filters__search-container__filter"></div>
                    </div>
                  </div>
                )}
              </>
            )}
            <div className="templated-experiences-index__main">
              {flags['tx_folders'] && !!selectedExperiences.size && (
                <div className="templated-experiences-index__table-actions">
                  <span key="counter" className="templated-experiences-index__table-actions__action counter">
                    {selectedExperiences.size} selected
                  </span>
                  <a
                    href="/"
                    key="add-labels"
                    className="templated-experiences-index__table-actions__action"
                    onClick={handleAddLabels}
                  >
                    Add labels
                  </a>
                  <a
                    href="/"
                    key="delete"
                    className="templated-experiences-index__table-actions__action"
                    onClick={handleBulkDelete}
                  >
                    Delete
                  </a>
                </div>
              )}
              <FlightTable
                tableHeaders={tableHeader}
                tableData={tableData}
                isLoading={reduxTemplatedExperienceState.isLoading}
                loadingRowNumber={Number(filters.limit)}
                hasError={!!reduxTemplatedExperienceState.errorMessage}
                emptyState={<div className="templated-experiences-index__empty">There are no experiences</div>}
                errorState={
                  <div className="templated-experiences-index__error-message">
                    Error: {reduxTemplatedExperienceState.errorMessage?.error?.exceptionMessage}
                  </div>
                }
                allowRowSelect
                allowMultiSelect={flags['tx_folders']}
                selectedDataKey={selectedExperiences}
                handleDataSelect={handleDataSelect}
                handleHeaderSort={handleHeaderSort}
                handleSelectAll={handleSelectAll}
                handleCheckboxMultiSelect={handleCheckboxMultiSelect}
                hasPaginationBeforeTable={false}
                hasPaginationAfterTable
                sortByKey={String(filters.sortby)}
                sortOrder={String(filters.sortorder)}
                paginationProps={{
                  totalPageNumber: maxPage,
                  currentPageNumber: Number(filters.page),
                  rowsPerPageOptions: [10, 20, 40, 60],
                  currentRowsPerPage: Number(filters.limit),
                  handlePageChange: onPageChange,
                  handleRowsPerPageChange: onRowPerPageChange,
                }}
              />
            </div>
          </>
        )}
        {!tableData?.length && !reduxTemplatedExperienceState.isLoading && <EmptyState cta={handleCreateExperience} />}
      </div>
      <ConfirmModal {...confirmModalProps} />
      <LabelsModal {...labelsModalProps} />
    </div>
  );
}
