import '../ZoneModuleSetup/ZoneModuleSetup.scss';
import './ModuleLayout.scss';

import React, { useContext } from 'react';
import CodeEditor from '../../../Shared/CodeEditor/CodeEditor';
import { FlightButton } from '@flybits/design-system';
import { ZM_DETAILS, ZM_DETAILS_CLASSES } from '../ZoneModuleSetup/constants';
import PrioritizationContext from 'pages/ZonesV2/PrioritizationContext/PrioritizationContext';
import { TNavigationButton } from '../types';
import { CODE_LANGUAGE } from 'components/Zones/v2/zones.constants';

const MAIN_CLASS = 'module-layout';

const CLASS_BODY = `${MAIN_CLASS}__body`;
const CLASS_FIELD = `${CLASS_BODY}__field`;

const CLASS_FOOTER = `${MAIN_CLASS}__footer`;
const CLASS_FOOTER_BUTTONS = `${CLASS_FOOTER}__footer-buttons`;

export type ModuleLayoutErrorMessages = {
  layoutHTML: string;
  styleCSS: string;
};

type ModuleLayoutProps = {
  navigateToPrev?: TNavigationButton;
  navigateToNext?: TNavigationButton;
};

function ModuleLayout({ navigateToPrev, navigateToNext }: ModuleLayoutProps) {
  const { selectedModule: module, setSelectedModule: setModuleValues } = useContext(PrioritizationContext);

  const saveLayoutHTML = (layoutHTML: string) => {
    setModuleValues((prev) => ({ ...prev, layoutHTML }));
  };

  const saveLayoutCSS = (styleCSS: string) => {
    setModuleValues((prev) => ({ ...prev, styleCSS }));
  };

  return (
    <div className={ZM_DETAILS}>
      <div className={ZM_DETAILS_CLASSES.FORM}>
        <div className={MAIN_CLASS}>
          <div className={CLASS_BODY}>
            <div className={CLASS_FIELD}>
              <CodeEditor
                title="HTML"
                code={module?.layoutHTML || ''}
                codeLanguage={CODE_LANGUAGE.HTML}
                handleBlur={saveLayoutHTML}
              />
            </div>
            <div className={CLASS_FIELD}>
              <CodeEditor
                title="CSS"
                code={module?.styleCSS || ''}
                codeLanguage={CODE_LANGUAGE.CSS}
                handleBlur={saveLayoutCSS}
              />
            </div>
          </div>
          <div className={CLASS_FOOTER}>
            <div className={CLASS_FOOTER_BUTTONS}>
              {!!navigateToPrev && (
                <FlightButton
                  ariaLabel={navigateToPrev.label}
                  label={navigateToPrev.label}
                  onClick={navigateToPrev.cta}
                  disabled={navigateToPrev.isDisabled}
                />
              )}
              {!!navigateToNext && (
                <FlightButton
                  ariaLabel={navigateToNext.label}
                  label={navigateToNext.label}
                  onClick={navigateToNext.cta}
                  disabled={navigateToNext.isDisabled}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModuleLayout;
