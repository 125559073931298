import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FlightButton,
  FlightCheckbox,
  FlightDropdown,
  FlightRadioButton,
  FlightTextInput,
} from '@flybits/design-system';
import useDebounce from 'hooks/useDebounce';
import './FilterByLabel.scss';
import LabelsAPI from 'services/api/labels.api';
import { useQuery } from '@tanstack/react-query';
import { LABEL_RULE } from 'components/Analytics/types';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';

type TFilterByLabelProps = {
  onLabelSelect: (label: string[], rules: LABEL_RULE) => void;
};

const MAIN_CLASS = 'filter-by-label';
const CLASSES = {
  DROPDOWN_WRAPPER: `${MAIN_CLASS}__dropdown-wrapper`,
  DROPDOWN_CONTENT: `${MAIN_CLASS}__dropdown-content`,
  DROPDOWN_SECTION: `${MAIN_CLASS}__dropdown-section`,
  LABEL_LIST: `${MAIN_CLASS}__label-list`,
  CLEAR_ALL_BUTTON: `${MAIN_CLASS}__clear-all-button`,
  SPINNER: `${MAIN_CLASS}__spinner`,
};

const FilterByLabel: React.FC<TFilterByLabelProps> = ({ onLabelSelect }) => {
  const labelsApi = useMemo(() => new LabelsAPI(), []);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [rules, setRules] = useState<LABEL_RULE>(LABEL_RULE.CONTAINS_ALL_OF);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const debouncedSelectedLabels = useDebounce(selectedLabels, 500);
  const fetchLabels = useCallback(
    async (search: string) => {
      const res = await labelsApi.getLabels(search);
      return res.data;
    },
    [labelsApi],
  );
  const {
    data: labels,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['labels', debouncedSearchTerm],
    queryFn: () => fetchLabels(debouncedSearchTerm),
    refetchOnWindowFocus: false,
  });

  const handleRuleSelect = (value: LABEL_RULE) => {
    setRules(value as LABEL_RULE);
  };

  const handleSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setSearchTerm(value);
  };

  const handleClearAllClick = () => {
    setSelectedLabels([]);
  };

  const handleLabelSelect = (label: string) => {
    setSelectedLabels((prev) => {
      if (prev.includes(label)) {
        return prev.filter((item) => item !== label);
      }
      return [...prev, label];
    });
  };

  useEffect(() => {
    onLabelSelect(debouncedSelectedLabels, rules);
  }, [debouncedSelectedLabels, rules, onLabelSelect]);

  return (
    <FlightDropdown
      trigger={
        <FlightButton
          label={selectedLabels.length > 0 ? `${selectedLabels.length} selected` : 'Labels'}
          theme="link"
          iconLeft="tag"
          iconRight="downCarrot"
          onClick={() => undefined}
          isPropagateUpperActions
        />
      }
      direction="bottom-left"
      className={CLASSES.DROPDOWN_WRAPPER}
    >
      <div className={CLASSES.DROPDOWN_CONTENT}>
        <div className={CLASSES.DROPDOWN_SECTION}>
          <FlightRadioButton
            label="Contains all of"
            value={LABEL_RULE.CONTAINS_ALL_OF}
            onSelect={handleRuleSelect}
            checked={rules === LABEL_RULE.CONTAINS_ALL_OF}
          />
          <FlightRadioButton
            label="Contains any of"
            value={LABEL_RULE.CONTAINS_ANY_OF}
            onSelect={handleRuleSelect}
            checked={rules === LABEL_RULE.CONTAINS_ANY_OF}
          />
        </div>
        <div className={CLASSES.DROPDOWN_SECTION}>
          <FlightTextInput
            value={searchTerm}
            onChange={handleSearchChange}
            width="100%"
            iconInput="search"
            hasClearIcon
            placeholderText="Search"
          />
          {(isLoading || isFetching) && (
            <div className={CLASSES.SPINNER}>
              <LoadingIcon />
            </div>
          )}
          <button
            className={CLASSES.CLEAR_ALL_BUTTON}
            onClick={handleClearAllClick}
            disabled={selectedLabels.length < 1}
          >
            Clear all
          </button>
          {!isLoading && !isFetching && (
            <ul className={CLASSES.LABEL_LIST}>
              {labels?.map((label) => (
                <li key={label}>
                  <FlightCheckbox
                    checkState={selectedLabels.includes(label) ? 'SELECTED' : 'UNSELECTED'}
                    label={label}
                    onSelect={() => handleLabelSelect(label)}
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </FlightDropdown>
  );
};

export default FilterByLabel;
