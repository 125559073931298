import './ConfirmationModal.scss';

import React, { useEffect, useRef, useState } from 'react';
import { FlightButton, FlightTextInput, getIcon } from '@flybits/design-system';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';
import { ConfirmationModalTypes } from '../shared.types';

const CLASS_MAIN = 'confirmation-modal';
const CLASSES = {
  CHILDREN: `${CLASS_MAIN}__children`,
  CLOSE_ICON: `${CLASS_MAIN}__children__close-icon`,
  BODY: `${CLASS_MAIN}__children__body`,
  ICON: `${CLASS_MAIN}__children__body__icon`,
  CONTAINER: `${CLASS_MAIN}__children__body__container`,
  INFO: `${CLASS_MAIN}__children__body__container__info`,
  TITLE: `${CLASS_MAIN}__children__body__container__info__title`,
  DESCRIPTION: `${CLASS_MAIN}__children__body__container__info__description`,
  ACTIONS: `${CLASS_MAIN}__children__body__container__actions`,
  ACTION_INPUT: `${CLASS_MAIN}__children__body__container__actions__input`,
  ACTION_BUTTON: `${CLASS_MAIN}__children__body__container__actions__button`,
  LOADING_CONTAINER: `${CLASS_MAIN}__loading-container`,
  LOADING_SPINNER: `${CLASS_MAIN}__loading-container__spinner`,
};
export interface ConfirmationModalProps extends React.ComponentProps<'dialog'> {
  isOpen: boolean;
  closeModal: () => void;
  theme: ConfirmationModalTypes;
  icon: JSX.Element;
  title: string;
  description: string | JSX.Element;
  primaryAction: {
    value: string;
    onClickHandler: () => void;
  };
  secondaryAction: {
    value: string;
    onClickHandler: () => void;
  };
  isLoading?: boolean;
  confirmationInput?: {
    placeholderText: string;
    matchText: string;
  };
  className?: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  closeModal,
  theme,
  icon,
  title,
  description,
  primaryAction,
  secondaryAction,
  isLoading,
  confirmationInput: { placeholderText: confirmationPlaceholderText, matchText: confirmationMatchText } = {},
  className = '',
}) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const [confirmInputVal, setConfirmInputVal] = useState('');
  const [confirmDisabled, setConfirmDisabled] = useState(true);

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }, [isOpen]);

  return (
    <dialog ref={dialogRef} onCancel={closeModal} className={`${CLASS_MAIN} ${className}`}>
      {isLoading && (
        <div className={CLASSES.LOADING_CONTAINER}>
          <div className={CLASSES.LOADING_SPINNER}>
            <LoadingIcon />
          </div>
        </div>
      )}
      <div className={CLASSES.CHILDREN}>
        <div className={CLASSES.CLOSE_ICON}>
          <button onClick={closeModal}>{getIcon('clear', {})}</button>
        </div>
        <div className={CLASSES.BODY}>
          <div className={CLASSES.ICON}>{icon}</div>
          <div className={CLASSES.CONTAINER}>
            <div className={CLASSES.INFO}>
              <div className={CLASSES.TITLE}>{title}</div>
              <div className={CLASSES.DESCRIPTION}>{description}</div>
            </div>
            <div className={CLASSES.ACTIONS}>
              {confirmationMatchText && (
                <div className={`${CLASSES.ACTION_INPUT}`}>
                  <FlightTextInput
                    name="merchant name"
                    width="100%"
                    value={confirmInputVal}
                    placeholderText={confirmationPlaceholderText || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const inputVal = e.target.value || '';
                      setConfirmInputVal(inputVal);
                      setConfirmDisabled(inputVal !== confirmationMatchText);
                    }}
                  />
                </div>
              )}
              <div className={`${CLASSES.ACTION_BUTTON} ${CLASSES.ACTION_BUTTON}--primary`}>
                <FlightButton
                  label={primaryAction.value}
                  ariaLabel={primaryAction.value}
                  onClick={primaryAction.onClickHandler}
                  theme={theme === ConfirmationModalTypes.PUBLISH ? 'primary' : 'minor'}
                  disabled={confirmationMatchText && confirmDisabled}
                />
              </div>
              <div className={`${CLASSES.ACTION_BUTTON} ${CLASSES.ACTION_BUTTON}--secondary`}>
                <FlightButton
                  label={secondaryAction.value}
                  ariaLabel={secondaryAction.value}
                  onClick={secondaryAction.onClickHandler}
                  theme={theme === ConfirmationModalTypes.DISCARD ? 'secondary' : 'minor'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default ConfirmationModal;
