import {
  Merchant,
  MerchantCategory,
  MerchantCreationPayload,
  MerchantUpdatePayload,
  Offer,
  OfferCreationPayload,
  OfferUpdatePayload,
} from 'pages/MerchantPortal/merchant-portal.types';
import BaseAPI from './base.api';
import { sleep } from 'helpers/common.helper';
import { AxiosError, AxiosHeaders } from 'axios';
import { PaginationResponse } from 'interface/shared/api.interface';

class MerchantAPI extends BaseAPI {
  private ROUTES = {
    categories: `kernel/journey/merchants/categories`,
    merchants: `kernel/journey/merchants`,
    offers: `kernel/journey/merchants/:merchantId/offers`,
  };

  private queryParams = {
    limit: 0,
    offset: 0,
    search: '',
    sortby: 'createdAt',
    sortorder: 'desc',
  };

  getMerchants(params = {}): Promise<PaginationResponse<Merchant>> {
    const queryParams = { ...this.queryParams, ...params };
    return this.GET(this.ROUTES.merchants, queryParams);
  }

  getMerchant(merchantId: string, includeOffers = false): Promise<Merchant> {
    return this.GET(this.buildURL(`${this.ROUTES.merchants}/:merchantId`, { merchantId }), { includeOffers });
  }

  createMerchant(merchantData: MerchantCreationPayload): Promise<Merchant> {
    return this.POST(this.ROUTES.merchants, merchantData);
  }

  deleteMerchant(merchantId: string) {
    return this.DELETE(this.buildURL(`${this.ROUTES.merchants}/:merchantId`, { merchantId }));
  }

  getOffers(merchantId: string): Promise<Offer[]> {
    return this.GET(this.buildURL(this.ROUTES.offers, { merchantId }), this.queryParams);
  }

  getOffer(merchantId: string, offerId: string): Promise<Offer> {
    return this.GET(this.buildURL(`${this.ROUTES.offers}/:offerId`, { merchantId, offerId }));
  }

  createOffer(merchantId: string, offerData: OfferCreationPayload): Promise<Offer> {
    return this.POST(this.buildURL(this.ROUTES.offers, { merchantId }), offerData);
  }

  // Using arrow fn for update functions because
  // when they are passed directly to the react-query useMutation,
  // it loses its binding to the MerchantAPI class instance
  updateMerchant = (merchantData: MerchantUpdatePayload): Promise<Merchant> =>
    this.PUT(this.buildURL(`${this.ROUTES.merchants}/:merchantId`, { merchantId: merchantData.id }), merchantData);

  updateOffer = (offerData: OfferUpdatePayload): Promise<Offer> =>
    this.PUT(
      this.buildURL(`${this.ROUTES.offers}/:offerId`, { merchantId: offerData.merchantId, offerId: offerData.id }),
      offerData,
    );

  // Mocks
  async getCategoriesMock(): Promise<MerchantCategory[]> {
    await sleep(1200);
    const { default: categories } = await import('../../pages/MerchantPortal/mocks/mock-categories');
    return categories;
  }

  async getMerchantsMock(): Promise<Merchant[]> {
    await sleep(1200);
    const { default: merchants } = await import('../../pages/MerchantPortal/mocks/mock-merchants-and-offers');
    return merchants as Merchant[];
  }

  async getMerchantMock(id: string): Promise<Merchant> {
    await sleep(1200);
    const { default: merchants } = await import('../../pages/MerchantPortal/mocks/mock-merchants-and-offers');
    const merchant = (merchants as Merchant[]).find((merchant) => id === merchant.id);
    if (!merchant) {
      const headers = new AxiosHeaders();
      const config = { url: 'https://floobotz.com', headers: headers };
      throw new AxiosError(
        'Error',
        'Error',
        config,
        { path: '/foo' },
        {
          status: 500,
          data: { error: 'error' },
          statusText: 'error',
          config,
          headers,
        },
      );
    }
    return merchant;
  }

  async getOffersMock(): Promise<Offer[]> {
    await sleep(1200);
    const { default: offers } = await import('../../pages/MerchantPortal/mocks/mock-offers');
    return offers as Offer[];
  }
  // End of Mocks
}

const merchantService = new MerchantAPI();

export default merchantService;
