const DEFAULT_QUERY_KEY = 'merchant-portal-settings';
import { useQuery } from '@tanstack/react-query';
import SettingAPI from 'services/api/setting.api';

const settingAPI = new SettingAPI();

export default function useMerchantOffers(merchantId: string) {
  const queryKey = [DEFAULT_QUERY_KEY, merchantId];

  const { data, isLoading, isError } = useQuery({
    queryKey,
    queryFn: () => settingAPI.getMerchantPortalSettings(),
    refetchOnWindowFocus: false,
    retry: false,
  });

  return {
    defaultJourneyTemplateId:
      typeof data?.settings?.journeyTemplates?.default === 'string' ? data.settings.journeyTemplates.default : '',
    isLoading,
    isError,
    queryKey,
  };
}
