import './EditMerchantOffer.scss';
import React, { useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useMerchant from 'hooks/useMerchant';
import OfferForm from 'components/MerchantPortal/OfferForm/OfferForm';
import OfferScheduleForm from 'components/MerchantPortal/OfferForm/OfferScheduleForm';
import OfferReview from 'components/MerchantPortal/OfferReview/OfferReview';
import UnsavedPrompt from 'components/Shared/UnsavedPrompt/UnsavedPrompt';
import { FlightButton } from '@flybits/design-system';
import { MerchantsRouteParams, OfferFormValues, OfferScheduleFormValues } from '../merchant-portal.types';
import Breadcrumbs from 'components/Shared/Breadcrumbs/Breadcrumbs';
import { OFFER_FORM_INIT_VALS } from 'pages/MerchantPortal/merchant-portal.constants';
import {
  getOfferScheduleInitValues,
  mapOfferDataToFormValues,
  mapOfferFormValuesToData,
} from 'pages/MerchantPortal/merchant-portal.helpers';
import useConfirmModal from 'hooks/useConfirmModal';
import IconPublishChanges from 'components/Shared/Icons/IconPublishChanges';
import { ConfirmationDialogProps, ConfirmationModalTypes } from 'components/Shared/shared.types';
import useDelayedLoadingState from 'hooks/useDelayedLoading';
import { useThunkDispatch as useDispatch } from 'hooks/reduxHooks';
import merchantService from 'services/api/merchants.api';
import { isAxiosError } from 'axios';
import { capitalizeFirstCharacter } from 'helpers/common.helper';
import { useQueryClient } from '@tanstack/react-query';

const EDIT_MERCHANT_OFFER = 'edit-merchant-offer';
const CLASSES = {
  HEADER: `${EDIT_MERCHANT_OFFER}__header`,
  INFO: `${EDIT_MERCHANT_OFFER}__header__info`,
  TITLE: `${EDIT_MERCHANT_OFFER}__header__title`,
  BUTTON: `${EDIT_MERCHANT_OFFER}__header__button`,
  SUBTITLE: `${EDIT_MERCHANT_OFFER}__header__subtitle`,
  BODY: `${EDIT_MERCHANT_OFFER}__body`,
  BREADCRUMBS: `${EDIT_MERCHANT_OFFER}__body__breadcrumbs`,
  CONTAINER: `${EDIT_MERCHANT_OFFER}__body__container`,
  LOADING_BAR: `${EDIT_MERCHANT_OFFER}__loading-bar`,
  LOADING_WRAPPER: `${EDIT_MERCHANT_OFFER}__loading-bar__wrapper`,
};
// NOTE: These are EXAMPLE icons. Please update to match with designs when implementing
const BREADCRUMB_ITEMS = [
  { icon: null, forceOutline: true, text: 'Edit offer information' },
  { icon: null, forceOutline: true, text: 'Edit schedule and budget' },
  { icon: null, forceOutline: true, text: 'Review and confirm' },
];

const confirmationDialogProps: ConfirmationDialogProps = {
  theme: ConfirmationModalTypes.PUBLISH,
  icon: <IconPublishChanges />,
  title: 'Publish Changes',
  description: `Are you sure you want to commit these changes to the merchant offer?`,
  primaryAction: {
    value: 'Publish changes',
  },
  secondaryAction: {
    value: 'Cancel',
  },
};

const EditMerchantOffer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { pid: projectId, merchantid, offerid } = useParams<MerchantsRouteParams>();
  const { merchant: selectedMerchant, isMerchantLoading, isMerchantError } = useMerchant(merchantid ?? '', true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isOfferUpdated, setOfferUpdated] = useState(false);
  const isDelayedSubmitting = useDelayedLoadingState(isSubmitting);
  const [offerData, setOfferData] = useState<OfferFormValues>({ ...OFFER_FORM_INIT_VALS });
  const [offerScheduleData, setOfferScheduleData] = useState<OfferScheduleFormValues>(getOfferScheduleInitValues());
  const [isOfferFormDirty, setOfferFormDirty] = useState(false);
  const [isOfferScheduleFormDirty, setOfferScheduleFormDirty] = useState(false);
  const [selectedStep, setSelectedStep] = useState(1);
  const [submitErrorMessage, setSubmitErrorMessage] = useState('');
  const [SaveMerchantOfferConfirmModal, showMerchantOfferConfirmModal] = useConfirmModal(confirmationDialogProps);

  const handleReviewSubmit = useCallback(async () => {
    try {
      if (selectedMerchant?.id && (await showMerchantOfferConfirmModal())) {
        const offer = selectedMerchant.offers?.find((offer) => offer.id === offerid);

        const updatedOffer = mapOfferFormValuesToData(
          selectedMerchant.id,
          {
            offer: offerData,
            schedule: offerScheduleData,
          },
          offer?.journeyInstanceId,
          offer?.journeyTemplateId,
        );
        setSubmitting(true);
        await merchantService.updateOffer(updatedOffer);
        await queryClient.invalidateQueries({ queryKey: ['merchant'] });
        await queryClient.invalidateQueries({ queryKey: ['merchants'] });
        setOfferUpdated(true);
      }
    } catch (e: any) {
      let errorMessage = isAxiosError(e) || e instanceof Error ? e.message : 'unknown';
      // handles better error message from backend
      if (e?.response?.data?.error?.exceptionMessage) {
        errorMessage = e.response.data.error.exceptionMessage;
      }
      setSubmitErrorMessage(errorMessage);
      setOfferUpdated(false);
    } finally {
      setSubmitting(false);
    }
  }, [showMerchantOfferConfirmModal, offerData, offerScheduleData, selectedMerchant, queryClient, offerid]);

  useEffect(() => {
    if (isMerchantError) {
      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: {
          title: 'Error',
          content: 'Merchant could not found! Please try again.',
          type: 'error',
        },
      });
      history.push(`/project/${projectId}/merchants`);
    }

    if (!isMerchantLoading && selectedMerchant?.name) {
      const offer = selectedMerchant.offers?.find((offer) => offer.id === offerid);
      if (offer?.id) {
        const { offer: offerInfo, schedule: scheduleInfo } = mapOfferDataToFormValues(offer, selectedMerchant.name);
        setOfferData((data) => ({ ...data, ...offerInfo }));
        setOfferScheduleData((data) => ({ ...data, ...scheduleInfo }));
      } else {
        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: {
            title: 'Error',
            content: 'Offer could not be found! Please try again.',
            type: 'error',
          },
        });
        history.push(`/project/${projectId}/merchants`);
      }
    }
  }, [isMerchantLoading, isMerchantError, selectedMerchant, dispatch, history, projectId, offerid]);

  useLayoutEffect(() => {
    if (!isDelayedSubmitting) {
      if (isOfferUpdated) {
        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: { content: 'Offer details successfully saved.', type: 'success' },
        });
        history.push(`/project/${projectId}/merchants`);
      }

      if (submitErrorMessage) {
        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: {
            title: 'Error',
            content: `The offer "${offerData.title}" could not be saved. ${capitalizeFirstCharacter(
              submitErrorMessage,
            )}`,
            type: 'error',
          },
        });
      }
    }
  }, [isDelayedSubmitting, history, projectId, offerData.title, isOfferUpdated, submitErrorMessage, dispatch]);

  return (
    <div className={EDIT_MERCHANT_OFFER}>
      <header className={CLASSES.HEADER}>
        <FlightButton
          iconLeft="clear"
          theme="link"
          ariaLabel="navigate back"
          onClick={() => history.push(`/project/${projectId}/merchants`)}
        />
        <div className={CLASSES.TITLE}>Edit Merchant Offer</div>
        <div className={`${CLASSES.HEADER}__gutter`}></div>
      </header>
      {isMerchantLoading || isDelayedSubmitting ? (
        <div className={CLASSES.LOADING_WRAPPER} aria-label="merchant and offer are loading">
          <div className={CLASSES.LOADING_BAR}>
            <div></div>
          </div>
        </div>
      ) : (
        <div className={CLASSES.BODY}>
          <section className={CLASSES.BREADCRUMBS}>
            <Breadcrumbs items={BREADCRUMB_ITEMS} selectedStep={selectedStep} />
          </section>
          {selectedStep === 1 && (
            <section className={CLASSES.CONTAINER}>
              <OfferForm
                onSubmit={(values: OfferFormValues) => {
                  setOfferData(values);
                  setSelectedStep(2);
                }}
                data={offerData}
                setDirty={setOfferFormDirty}
                isEditMode
              />
            </section>
          )}
          {selectedStep === 2 && (
            <section className={CLASSES.CONTAINER}>
              <OfferScheduleForm
                onSubmit={(values: OfferScheduleFormValues) => {
                  setOfferScheduleData(values);
                  setSelectedStep(3);
                }}
                onPrevious={() => setSelectedStep(1)}
                data={offerScheduleData}
                setDirty={setOfferScheduleFormDirty}
                isPreviousFormDirty={isOfferFormDirty}
                isEditMode
              />
            </section>
          )}
          {selectedStep === 3 && (
            <section className={CLASSES.CONTAINER}>
              <OfferReview
                onSubmit={handleReviewSubmit}
                onPrevious={() => setSelectedStep(2)}
                data={{ offer: offerData, schedule: offerScheduleData }}
              />
            </section>
          )}
        </div>
      )}
      {SaveMerchantOfferConfirmModal()}
      <UnsavedPrompt
        when={!isOfferUpdated && selectedStep < 4 && (isOfferFormDirty || isOfferScheduleFormDirty)}
        unblockPaths={['/edit-offer']}
        dialogProps={{
          description: 'This will undo all the information you have entered since you started.',
        }}
      />
    </div>
  );
};

export default EditMerchantOffer;
