import './CodeEditor.scss';
import React, { useState, useCallback, useEffect } from 'react';
import Editor from 'react-simple-code-editor';
import { Grammar, highlight, languages } from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-css';
import 'prismjs/themes/prism-solarizedlight.css';
import { html_beautify as htmlBeautify, css_beautify as cssBeautify } from 'js-beautify';
import { CODE_BEAUTIFICATION, CODE_LANGUAGE } from 'components/Zones/v2/zones.constants';
import { FlightButton } from '@flybits/design-system';

type EditorProps = {
  title: string;
  code: string;
  codeLanguage: CODE_LANGUAGE;
  disabled?: boolean;
  handleBlur: (code: string) => void;
  handleChange?: (code: string) => void;
};

const CodeEditor = ({ title, code, codeLanguage, disabled, handleBlur }: EditorProps) => {
  const [codeValue, setCodeValue] = useState('');

  const formatCode = useCallback((lang: CODE_LANGUAGE, code = '') => {
    switch (lang) {
      case CODE_LANGUAGE.HTML:
        const beautifedHTML = htmlBeautify(code, CODE_BEAUTIFICATION.htmlBeautifyOptions);
        setCodeValue(beautifedHTML);
        return beautifedHTML;
      case CODE_LANGUAGE.CSS:
        const beautifedCSS = cssBeautify(code, CODE_BEAUTIFICATION.cssBeautifyOptions);
        setCodeValue(beautifedCSS);
        return beautifedCSS;
      default:
        return code;
    }
  }, []);
  const handleCodeChange = useCallback((code = '') => setCodeValue(code), []);
  const handleCodeBlur = useCallback(
    (code = '') => {
      const beautifiedCode = formatCode(codeLanguage, code);
      handleBlur(beautifiedCode);
    },
    [formatCode, codeLanguage, handleBlur],
  );

  const hightlightWithLineNumbers = (input: string, grammar: Grammar, language: string) =>
    highlight(input, grammar, language)
      .split('\n')
      .map((line, i) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
      .join('\n');

  useEffect(() => {
    setCodeValue(formatCode(codeLanguage, code));
  }, [formatCode, code, codeLanguage]);

  return (
    <div className="panel panel-default">
      <h3 className="panel-heading">
        {' ' + title}
        <FlightButton theme="link" iconRight="gantt" label="Beautify" onClick={() => formatCode(codeLanguage, code)} />
      </h3>
      <div className="panel-editor">
        <Editor
          highlight={(code) =>
            hightlightWithLineNumbers(
              code,
              codeLanguage === CODE_LANGUAGE.HTML ? languages.html : languages.css,
              codeLanguage,
            )
          }
          value={codeValue}
          tabSize={CODE_BEAUTIFICATION.tabSize}
          padding={10}
          textareaId="codeArea"
          className={`editor`}
          style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            lineHeight: 1.4,
            fontSize: '12px',
            outline: 0,
          }}
          disabled={disabled}
          onValueChange={handleCodeChange}
          onBlur={() => handleCodeBlur(codeValue)}
        />
      </div>
      <div className={`code-editor__line-col`}></div>
    </div>
  );
};

export default CodeEditor;
