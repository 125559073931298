import { AuditState, AuditActionType } from './audit.type';
import { AnyAction } from 'redux';
import { User, ServiceAccount } from 'interface/auth/auth.interface';

const initialState: AuditState = {
  logs: [],
  users: [],
  serviceAccounts: [],
  pagination: {
    totalRecords: 0,
    limit: 20,
    offset: 0,
    sort: 'desc',
    sortBy: 'timestamp',
  },
  isLoading: false,
  isAuditInitialized: false,
};

export default (state: AuditState = initialState, action: AnyAction): AuditState => {
  switch (action.type) {
    case AuditActionType.SET_AUDIT_LOG_DATA:
      return {
        ...state,
        logs: action.payload,
      };
    case AuditActionType.SET_AUDIT_PAGINATION_DATA:
      return {
        ...state,
        pagination: action.payload,
      };
    case AuditActionType.SET_AUDIT_USERS_DATA:
      return {
        ...state,
        users: action.payload as User[],
      };
    case AuditActionType.SET_AUDIT_SERVICE_ACCOUNTS_DATA:
      return {
        ...state,
        serviceAccounts: action.payload as ServiceAccount[],
      };
    case AuditActionType.SET_AUDIT_LOADING:
      return {
        ...state,
        isLoading: action.payload as boolean,
      };

    case AuditActionType.SET_AUDIT_INITIALIZED:
      return {
        ...state,
        isAuditInitialized: action.payload as boolean,
      };
    default:
      return {
        ...state,
      };
  }
};
