import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Merchant, MerchantUpdatePayload } from 'pages/MerchantPortal/merchant-portal.types';
import { useCallback, useState } from 'react';
import merchantService from 'services/api/merchants.api';
import { isAxiosError } from 'axios';

type UseMerchantResult = {
  merchant: Merchant | undefined;
  isMerchantLoading: boolean;
  isMerchantError: boolean;
  handleUpdateMerchant: (merchant: MerchantUpdatePayload) => void;
  isMerchantUpdating: boolean;
  isMerchantUpdated: boolean;
  merchantUpdateError: string;
};

export default function useMerchant(id: string, includeOffers = false): UseMerchantResult {
  const queryKey = ['merchant', { id, includeOffers }];

  const queryClient = useQueryClient();

  const [merchantUpdateError, setMerchantUpdateError] = useState('');

  const {
    data: merchant,
    isLoading: isMerchantLoading,
    isError: isMerchantError,
  } = useQuery<Merchant>({
    queryKey,
    queryFn: () => merchantService.getMerchant(id, includeOffers),
    refetchOnWindowFocus: false,
  });

  // Mutation to update the merchant
  const mutation = useMutation(merchantService.updateMerchant, {
    onMutate: async (updatedMerchant) => {
      await queryClient.cancelQueries(queryKey);

      // Snapshot the previous merchant data
      const previousData = queryClient.getQueryData<Merchant>(queryKey);

      // Optimistically update the merchant before backend responds
      queryClient.setQueryData<Merchant>(queryKey, (oldMerchantData) => {
        if (!oldMerchantData) {
          return;
        }

        return {
          ...oldMerchantData,
          ...(updatedMerchant as Merchant),
        };
      });

      return { previousData };
    },
    onError: (error: any, updatedMerchant, context) => {
      // Rollback to the previous state
      queryClient.setQueryData(queryKey, context?.previousData);

      let errorMessage = isAxiosError(error) || error instanceof Error ? error.message : 'unknown';
      if (error?.response?.data?.error?.exceptionMessage) {
        errorMessage = error.response.data.error.exceptionMessage;
      }

      setMerchantUpdateError(`The Merchant ${updatedMerchant.name} could not be edited. ${errorMessage}`);
    },
    onSettled: () => {
      // Refetch the merchant data to ensure consistency
      queryClient.invalidateQueries(queryKey);

      // Refetch the merchants data to update the merchants listing page
      // ToDo: Find a performant solution to update only the affected merchant row in the table
      queryClient.invalidateQueries(['merchants']);
    },
  });

  const handleUpdateMerchant = useCallback((merchant: MerchantUpdatePayload) => mutation.mutate(merchant), [mutation]);

  return {
    merchant,
    isMerchantLoading,
    isMerchantError,
    handleUpdateMerchant,
    isMerchantUpdating: mutation.isLoading,
    isMerchantUpdated: mutation.isSuccess,
    merchantUpdateError,
  };
}
