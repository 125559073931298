import './Zones.scss';
import React, { useContext, useState } from 'react';
import useFeatureFlag from 'hooks/useFeatureFlag';
import ZoneModuleSetup from 'components/Zones/v2/ZoneModuleSetup/ZoneModuleSetup';
import SetupZones from 'components/Zones/SetupZones/SetupZones';
import PrioritizationContext from './PrioritizationContext/PrioritizationContext';
import { ZoneDefaults } from 'components/Zones/v2/ZoneModuleSetup/constants';
import { MODULE_LAYOUTS } from 'components/Zones/v2/types';
import { ZonesListPageParams } from 'pages/ZonesV2/Zone';
import { useHistory, useParams } from 'react-router-dom';

const MAIN_CLASS = 'zm';
const CLASSES = {
  CONTAINER: `${MAIN_CLASS}__container`,
};
const ZonesOnboarding = function () {
  const { flags } = useFeatureFlag();
  const history = useHistory();
  const { pid } = useParams<ZonesListPageParams>();

  const [hasInitializedWorkflow, setHasInitializedWorkflow] = useState(false);
  const { zones, isZoneConfigLoading, setSelectedModule, setSelectedZone } = useContext(PrioritizationContext);

  if (!flags['tx_zones_modules_v2']) {
    return false;
  }

  if (zones && zones.length >= 1) {
    history.replace(`/project/${pid}/zones/v2`);
  }

  return (
    <>
      {hasInitializedWorkflow && !isZoneConfigLoading && (
        <div className={CLASSES.CONTAINER}>
          <ZoneModuleSetup />
        </div>
      )}
      {(!hasInitializedWorkflow || isZoneConfigLoading) && (
        <SetupZones
          isLaunchingZone={isZoneConfigLoading}
          onLaunchZone={() => {
            // Reset all values when Onboarding launched.
            setSelectedZone(ZoneDefaults);
            setSelectedModule({ layout: MODULE_LAYOUTS.VERTICAL });
            setHasInitializedWorkflow(true);
          }}
        />
      )}
    </>
  );
};

export default ZonesOnboarding;
