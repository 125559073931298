import {
  MERCHANT_VERIFICATION_STATUSES,
  OFFER_VERIFICATION_STATUSES,
  OFFER_STATUSES,
  STATUS_INFO,
  STATUS_ENTITIES,
  STATUS_MAP_TYPE,
} from 'pages/MerchantPortal/merchant-portal.types';

export const MERCHANT_VERIFICATION_STATUS_INFO = {
  [MERCHANT_VERIFICATION_STATUSES.VERIFIED]: {
    title: 'Verified',
    description: 'The merchant information and payment details have been successfully verified',
  },
  [MERCHANT_VERIFICATION_STATUSES.PENDING]: {
    title: 'Pending Verification',
    description: 'The merchant information and payment details are awaiting verification',
  },
  [MERCHANT_VERIFICATION_STATUSES.FAILED]: {
    title: 'Verification Failed',
    description: 'The merchant information and payment details could not be verified',
  },
  [MERCHANT_VERIFICATION_STATUSES.DELETED]: {
    title: 'Deleted',
    description: 'The merchant has been successfully deleted from both Flybits and Affinity',
  },
  [MERCHANT_VERIFICATION_STATUSES.PENDING_DELETION]: {
    title: 'Pending Deletion',
    description: 'The deletion request has been sent to Affinity and is awaiting confirmation',
  },
} as STATUS_INFO;

export const OFFER_VERIFICATION_STATUS_INFO = {
  [OFFER_VERIFICATION_STATUSES.VERIFIED]: {
    title: 'Verified',
    description: 'The offer details have been successfully verified',
  },
  [OFFER_VERIFICATION_STATUSES.PENDING]: {
    title: 'Pending Verification',
    description: 'The offer is awaiting verification',
  },
  [OFFER_VERIFICATION_STATUSES.FAILED]: {
    title: 'Verification Failed',
    description: 'The offer details could not be verified',
  },
} as STATUS_INFO;

export const OFFER_STATUS_INFO = {
  [OFFER_STATUSES.ACTIVE]: {
    title: 'Active',
    description: 'The offer has been launched',
  },
  [OFFER_STATUSES.CANCELLED]: {
    title: 'Cancelled',
    description: 'After the offer was launched, the merchant decided to cancel it',
  },
  [OFFER_STATUSES.DRAFT]: {
    title: 'Draft',
    description: 'The offer has not been launched yet',
  },
  [OFFER_STATUSES.EXPIRED]: {
    title: 'Expired',
    description: "The offer's schedule has expired",
  },
  [OFFER_STATUSES.INACTIVE]: {
    title: 'Inactive',
    description: 'The offer is currently deactivated',
  },
  [OFFER_STATUSES.SCHEDULED]: {
    title: 'Scheduled',
    description: 'The offer is scheduled to launch in the future',
  },
  [OFFER_STATUSES.ERROR]: {
    title: 'Error',
    description: 'There is an error in the offer',
  },
} as STATUS_INFO;

export const STATUS_MAP: STATUS_MAP_TYPE = {
  [STATUS_ENTITIES.MERCHANT_VERIFICATION_STATUS]: MERCHANT_VERIFICATION_STATUS_INFO,
  [STATUS_ENTITIES.OFFER_VERIFICATION_STATUS]: OFFER_VERIFICATION_STATUS_INFO,
  [STATUS_ENTITIES.OFFER_STATUS]: OFFER_STATUS_INFO,
};
