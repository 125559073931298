import './OfferForm.scss';
import React, { useEffect } from 'react';
import { FlightButton, FlightSelect, FlightTextArea, FlightTextInput } from '@flybits/design-system';
import { Field, Form, Formik, getIn } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
// import ScrollToFormikErrorField from 'components/Shared/ScrollToFormikErrorField/ScrollToFormikErrorField';
import { OFFER_VERIFICATION_STATUSES, OfferFormValues } from 'pages/MerchantPortal/merchant-portal.types';
import { capitalize } from 'lodash';
import { currencyFormatter, formatCurrency, validateInput } from 'pages/MerchantPortal/merchant-portal.helpers';
import {
  MerchantOfferVerificationStatusOptions,
  OfferValidationSchema,
} from 'pages/MerchantPortal/merchant-portal.constants';
import { useAppSelector as useSelector } from 'hooks/reduxHooks';

const OFFER_FORM = 'offer-form';
const CLASSES = {
  CONTENT: `${OFFER_FORM}__content`,
  CONTENT_LEFT: `${OFFER_FORM}__content__left`,
  CONTENT_RIGHT: `${OFFER_FORM}__content__right`,
  CONTENT_FOOTER: `${OFFER_FORM}__content__footer`,
  CONTENT_MONEY: `${OFFER_FORM}__content__money`,
};

interface FormProps {
  onSubmit: (values: OfferFormValues) => void;
  data: OfferFormValues;
  setDirty: React.Dispatch<React.SetStateAction<boolean>>;
  isEditMode?: boolean;
}
interface SelectOptionProps {
  key: string;
  name: string;
}

function OfferForm(props: FormProps) {
  const { onSubmit, data, setDirty, isEditMode = false } = props;
  // TODO: raise this to level 90 after merchant portal is released for M1
  const isFBAdmin = useSelector((state) => state.auth.user?.level && state.auth.user.level >= 21);
  const history = useHistory();
  const { pid } = useParams<{ pid: string }>();

  const handleCancel = () => {
    history.push(`/project/${pid}/merchants`);
  };
  const initialValues = !isEditMode
    ? data
    : {
        ...data,
        spendThreshold: currencyFormatter.format(parseInt(data.spendThreshold.replace(/[^0-9]/g, ''), 10)),
        earnCap: currencyFormatter.format(parseInt(data.earnCap.replace(/[^0-9]/g, ''), 10)),
      };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={OfferValidationSchema}
      validateOnChange
      validateOnBlur
      validateOnMount
      enableReinitialize
      onSubmit={onSubmit}
    >
      {function DisplayForm({
        values,
        errors,
        isValid,
        touched,
        dirty,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) {
        useEffect(() => {
          setDirty(dirty);
        }, [dirty]);

        return (
          <Form className={OFFER_FORM} autoComplete="off">
            <header>
              <h2>Offer Information</h2>
              <p>Please provide the following information and details about the merchant offer</p>
            </header>
            <main className={CLASSES.CONTENT}>
              <section>
                <div className={CLASSES.CONTENT_LEFT}>
                  <h3>About the offer</h3>
                  <p>Please provide detailed information about the offer</p>
                </div>
                <div className={CLASSES.CONTENT_RIGHT}>
                  <label htmlFor="merchantName">
                    Merchant name<span>*</span>
                  </label>
                  <Field
                    type="text"
                    name="merchantName"
                    width="100%"
                    value={values.merchantName}
                    as={FlightTextInput}
                    disabled
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={!!errors.merchantName && touched.merchantName}
                  />
                  {!!errors.merchantName && touched.merchantName ? (
                    <span role="alert" aria-atomic="true" className="flight-text-input__error-message">
                      <b>Error:</b> {errors.merchantName}
                    </span>
                  ) : (
                    <span>Enter the name of the merchant for whom you are creating this offer </span>
                  )}
                  <label htmlFor="type">
                    Offer type<span>*</span>
                  </label>
                  <Field
                    type="select"
                    name="type"
                    hasLabelAnimation
                    label="Select an offer type"
                    width="100%"
                    disabled
                    options={[{ key: 'cashback', value: 1, name: 'Cashback' }]}
                    selected={values.type ? { key: values.type, name: `${capitalize(values.type)}` } : null}
                    dropdownMaxHeight="250px"
                    hasError={!!errors.type && touched.type}
                    as={FlightSelect}
                    handleOptionClick={(value: SelectOptionProps) => {
                      setFieldValue('type', value.key);
                    }}
                  />
                  {!!errors.type && touched.type ? (
                    <span role="alert" aria-atomic="true" className="flight-text-input__error-message">
                      <b>Error:</b> {errors.type}
                    </span>
                  ) : (
                    <span>Only cash back for pilot</span>
                  )}
                  <label htmlFor="redemptionLimit">
                    Redemption limit<span>*</span>
                  </label>
                  <Field
                    type="select"
                    name="redemptionLimit"
                    hasLabelAnimation
                    label="Select an offer type"
                    width="100%"
                    // TODO: This should be filled with "real" data
                    options={[
                      { key: 'just-once', name: 'Just once' },
                      { key: 'multiple', name: 'Multiple use' },
                    ]}
                    // TODO: This is temporary selection check
                    selected={
                      values.redemptionLimit
                        ? values.redemptionLimit === 'just-once'
                          ? { key: values.redemptionLimit, name: `Just once` }
                          : { key: values.redemptionLimit, name: `Multiple use` }
                        : null
                    }
                    hasError={!!errors.redemptionLimit && touched.redemptionLimit}
                    dropdownMaxHeight="250px"
                    as={FlightSelect}
                    handleOptionClick={(value: SelectOptionProps) => {
                      setFieldValue('redemptionLimit', value.key);
                    }}
                  />
                  {!!errors.redemptionLimit && touched.redemptionLimit ? (
                    <span role="alert" aria-atomic="true" className="flight-text-input__error-message">
                      <b>Error:</b> {errors.redemptionLimit}
                    </span>
                  ) : (
                    <span>Specify if the offer can be redeemed multiple times or just once per customer</span>
                  )}

                  <label htmlFor="percentage">
                    Cashback percentage<span>*</span>
                  </label>
                  <Field
                    type="select"
                    name="percentage"
                    hasLabelAnimation
                    label="Select cash back percentage"
                    width="100%"
                    // TODO: This should be filled with "real" data
                    options={[
                      { key: 1, value: 1, name: '1%' },
                      { key: 2, value: 2, name: '2%' },
                      { key: 3, value: 3, name: '3%' },
                      { key: 5, value: 5, name: '5%' },
                      { key: 10, value: 10, name: '10%' },
                    ]}
                    // TODO: This is temporary selection check
                    selected={values.percentage ? { key: values.percentage, name: `${values.percentage}%` } : null}
                    dropdownMaxHeight="250px"
                    as={FlightSelect}
                    hasError={!!errors.percentage && touched.percentage}
                    handleOptionClick={(value: SelectOptionProps) => {
                      setFieldValue('percentage', value.key);
                    }}
                  />
                  {!!errors.percentage && touched.percentage ? (
                    <span role="alert" aria-atomic="true" className="flight-text-input__error-message">
                      <b>Error:</b> {errors.percentage}
                    </span>
                  ) : (
                    <span>Specify the cash back percentage that users will receive when they redeem this offer</span>
                  )}

                  <label htmlFor="spendThreshold">
                    Spend threshold<span>*</span> <small>(minimum to spend)</small>
                  </label>
                  <Field
                    type="text"
                    name="spendThreshold"
                    width="100%"
                    value={values.spendThreshold}
                    as={FlightTextInput}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      formatCurrency(e, setFieldValue, 'spendThreshold')
                    }
                    onKeyDown={validateInput}
                    onBlur={handleBlur}
                    hasError={!!errors.spendThreshold && touched.spendThreshold}
                  />
                  {!!errors.spendThreshold && touched.spendThreshold ? (
                    <span role="alert" aria-atomic="true" className="flight-text-input__error-message">
                      <b>Error:</b> {errors.spendThreshold}
                    </span>
                  ) : (
                    <span>Specify the minimum amount the customer must spend to earn the cash back</span>
                  )}

                  <label htmlFor="earnCap">
                    Earn cap<span>*</span> <small>(maximun to earn)</small>
                  </label>
                  <Field
                    type="text"
                    name="earnCap"
                    width="100%"
                    value={values.earnCap}
                    as={FlightTextInput}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => formatCurrency(e, setFieldValue, 'earnCap')}
                    onKeyDown={validateInput}
                    onBlur={handleBlur}
                    hasError={!!errors.earnCap && touched.earnCap}
                  />
                  {!!errors.earnCap && touched.earnCap ? (
                    <span role="alert" aria-atomic="true" className="flight-text-input__error-message">
                      <b>Error:</b> {errors.earnCap}
                    </span>
                  ) : (
                    <span>Specify the maximum amount of cash back the customer can earn from this offer</span>
                  )}
                </div>
              </section>
              <hr />
              <section>
                <div className={CLASSES.CONTENT_LEFT}>
                  <h3>Offer Details</h3>
                  <p>Please provide a draft of what you think the offer copy should be</p>
                </div>
                <div className={CLASSES.CONTENT_RIGHT}>
                  <label htmlFor="title">
                    Offer title<span>*</span>
                  </label>
                  <Field
                    type="text"
                    name="title"
                    width="100%"
                    value={values.title}
                    as={FlightTextInput}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={!!errors.title && touched.title}
                  />
                  {!!errors.title && touched.title ? (
                    <span role="alert" aria-atomic="true" className="flight-text-input__error-message">
                      <b>Error:</b> {errors.title}
                    </span>
                  ) : (
                    <span>Enter the offer title that will be displayed in the mobile app</span>
                  )}
                  <div className="example">
                    <div>Example:</div>
                    <div>Enjoy [10%] Cashback at [merchant name]!</div>
                  </div>
                  <label htmlFor="description">
                    Description<span>*</span>
                  </label>
                  <Field
                    type="text"
                    name="description"
                    label=""
                    ariaLabel="Description field"
                    width="100%"
                    value={values.description}
                    as={FlightTextArea}
                    hideCharCounter
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={!!errors.description && touched.description}
                  />
                  {!!errors.description && touched.description ? (
                    <span role="alert" aria-atomic="true" className="flight-text-input__error-message">
                      <b>Error:</b> {errors.description}
                    </span>
                  ) : (
                    <span>
                      Enter a brief 1-2 line draft description of the offer that could be displayed in the mobile app
                    </span>
                  )}
                  <div className="example">
                    <div>Example:</div>
                    <div>
                      Get up to 10% cashback on your total bill at [Merchant Name]. Enjoy our cozy ambiance and friendly
                      service while indulging in our delicious menu options, including our famous weekend brunch. Spend
                      today and receive your cashback as a credit on your next credit card statement. Terms and
                      conditions apply.
                    </div>
                  </div>
                </div>
              </section>
              {isEditMode && isFBAdmin && (
                <>
                  <hr />
                  <section>
                    <div className={CLASSES.CONTENT_LEFT}>
                      <h3>Offer Verification Status</h3>
                      <p>Only Admin users can make changes to this field</p>
                    </div>
                    <div className={CLASSES.CONTENT_RIGHT}>
                      <label htmlFor="offer-verification-status">
                        Verification Status<span>*</span>
                      </label>
                      <Field
                        type="select"
                        name="verification.verificationStatus"
                        hasLabelAnimation
                        label="Select a status"
                        width="100%"
                        options={MerchantOfferVerificationStatusOptions}
                        selected={MerchantOfferVerificationStatusOptions.find(
                          (opt) => opt.key === values.verification?.verificationStatus,
                        )}
                        dropdownMaxHeight="250px"
                        as={FlightSelect}
                        hasError={
                          !!getIn(errors, 'verification.verificationStatus') &&
                          getIn(touched, 'verification.verificationStatus')
                        }
                        handleOptionClick={(value: SelectOptionProps) => {
                          setFieldValue('verification.verificationStatus', value.key);
                        }}
                      />
                      {!!getIn(errors, 'verification.verificationStatus') &&
                      getIn(touched, 'verification.verificationStatus') ? (
                        <span role="alert" aria-atomic="true" className="flight-text-input__error-message">
                          <b>Error:</b> {getIn(errors, 'verification.verificationStatus')}
                        </span>
                      ) : (
                        <span>Select the offer verification status</span>
                      )}
                      <label htmlFor="offer-verification-notes">Verification Failed Notes</label>
                      <Field
                        type="text"
                        name="verification.notes"
                        width="100%"
                        value={values.verification?.notes}
                        as={FlightTextArea}
                        disabled={values.verification?.verificationStatus !== OFFER_VERIFICATION_STATUSES.FAILED}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        hideCharCounter
                        hasError={!!getIn(errors, 'verification.notes') && getIn(touched, 'verification.notes')}
                      />
                      {!!getIn(errors, 'verification.notes') && getIn(touched, 'verification.notes') ? (
                        <span role="alert" aria-atomic="true" className="flight-text-input__error-message">
                          <b>Error:</b> {getIn(errors, 'verification.notes')}
                        </span>
                      ) : (
                        <span>Add any additional notes about the verification status of this offer</span>
                      )}
                    </div>
                  </section>
                </>
              )}
              <section className={CLASSES.CONTENT_FOOTER}>
                <FlightButton theme="secondary" onClick={handleCancel} label={`Cancel`} />
                <FlightButton theme="primary" onClick={handleSubmit} label={`Next`} disabled={!isValid} />
              </section>
            </main>
            {/* <ScrollToFormikErrorField /> */}
          </Form>
        );
      }}
    </Formik>
  );
}

export default OfferForm;
