import React, { useEffect } from 'react';
import { FlightButton } from '@flybits/design-system';
import { ENGAGEMENT_DATA_TYPES } from 'components/Analytics/types';
import { getCurrentTimestampInMs } from 'helpers/common.helper';
import { CLASSES } from '../constants';

export interface DownloadEngagementDataProps extends React.ComponentProps<'div'> {
  data: Record<string, string>[];
  type: ENGAGEMENT_DATA_TYPES;
  isDownloading: boolean;
  onClickDownload: () => void;
}

const DownloadEngagementData: React.FC<DownloadEngagementDataProps> = ({
  data,
  type,
  isDownloading,
  onClickDownload,
}) => {
  useEffect(() => {
    if (!data || !data.length) {
      return;
    }

    // Convert JSON data to CSV
    const csvHeaders = Object.keys(data[0]).join(','); // Use keys of the first object as headers
    const csvRows = data.map((row) =>
      Object.values(row)
        .map((value) => (typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value)) // Escape quotes in strings
        .join(','),
    );

    const csvContent = [csvHeaders, ...csvRows].join('\n');

    // Create a downloadable blob
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor link for download
    const link = document.createElement('a');
    link.className = CLASSES.DOWNLOAD_LINK;
    link.href = url;
    link.setAttribute('download', `${type}_engagement_data_${getCurrentTimestampInMs()}.csv`);
    document.body.appendChild(link);
    link.click();

    return () => {
      // Clean up temporary anchor link
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    };
  }, [data, type]);

  return (
    <FlightButton
      label="Download"
      iconLeft="download"
      onClick={onClickDownload}
      theme="secondary"
      loading={isDownloading}
      disabled={isDownloading}
    />
  );
};

export default DownloadEngagementData;
