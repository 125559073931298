import './ModuleSectionComponents.scss';

import React, { PropsWithChildren } from 'react';
import { ReactComponent as AndroidPink } from 'assets/icons/android_pink.svg';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';
import { EVENT_KEYS } from 'types/events';

const CLASS_MODULE_SECTION = 'module-section';

interface ModuleSectionRootProps extends React.ComponentProps<'div'> {
  sectionNumber: number;
}

export const ModuleSectionRoot: React.FC<ModuleSectionRootProps> = ({ sectionNumber, children, ...props }) => (
  <div className={CLASS_MODULE_SECTION} {...props}>
    <div className={`${CLASS_MODULE_SECTION}__section-number`}>{sectionNumber}</div>
    <div className={`${CLASS_MODULE_SECTION}__section-content`}>{children}</div>
  </div>
);

interface ModuleSectionHeaderProps extends React.ComponentProps<'div'> {
  title: string;
  subtext: JSX.Element;
  icon?: JSX.Element;
}

const CLASS_MODULE_SECTION_HEADER = 'module-section-header';

export const ModuleSectionHeader: React.FC<ModuleSectionHeaderProps> = ({
  title,
  subtext,
  icon = <AndroidPink />,
  ...props
}) => (
  <div className={`${CLASS_MODULE_SECTION_HEADER}`} {...props}>
    <div className={`${CLASS_MODULE_SECTION_HEADER}__title-section`}>
      {icon}
      <div className={`${CLASS_MODULE_SECTION_HEADER}__title-section__title`}>{title}</div>
    </div>
    <div className={`${CLASS_MODULE_SECTION_HEADER}__subtitle`}>{subtext}</div>
  </div>
);

const CLASS_MODULE_SECTION_ITEMS = 'module-section-items';

export const ModuleSectionItems: React.FC<React.ComponentProps<'div'>> = ({ children, ...props }) => (
  <div className={CLASS_MODULE_SECTION_ITEMS} {...props}>
    {children}
  </div>
);

interface ModuleSectionItemProps extends React.ComponentProps<'div'> {
  index: number;
  title: string;
  iconUrl?: string;
  onEvent: () => void;
}

const CLASS_MODULE_SECTION_ITEM = 'module-section-item';

export const ModuleSectionItem: React.FC<ModuleSectionItemProps> = ({
  index,
  title,
  children,
  iconUrl,
  onEvent,
  ...props
}) => (
  <div
    className={CLASS_MODULE_SECTION_ITEM}
    {...props}
    tabIndex={0}
    role="button"
    onClick={onEvent}
    onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === EVENT_KEYS.ENTER) {
        onEvent();
      }
    }}
  >
    <div className={`${CLASS_MODULE_SECTION_ITEM}__left-side`}>
      <div className={`${CLASS_MODULE_SECTION_ITEM}__left-side__index-number`}>{index}</div>
      {!!iconUrl && (
        <div className={`${CLASS_MODULE_SECTION_ITEM}__left-side__content-icon`}>
          <img src={iconUrl} alt={'content-icon'} />
        </div>
      )}
      <div className={`${CLASS_MODULE_SECTION_ITEM}__left-side__content-name`}>{title}</div>
    </div>
    <div className={`${CLASS_MODULE_SECTION_ITEM}__right-side`}>{children}</div>
  </div>
);

const CLASS_MODULE_SECTION_LOADER = `${CLASS_MODULE_SECTION_ITEMS}--spinner`;
export const ModuleSectionLoader = () => {
  return (
    <div className={CLASS_MODULE_SECTION_LOADER}>
      <LoadingIcon />
    </div>
  );
};

const CLASS_MODULE_SECTION_EMPTY_ITEM = `${CLASS_MODULE_SECTION_ITEM}--empty`;
export const ModuleSectionEmptyItem = ({ children }: PropsWithChildren) => (
  <div className={CLASS_MODULE_SECTION_EMPTY_ITEM}>{children}</div>
);

const CLASS_MODULE_SECTION_FOOTER = 'module-section-footer';

export const ModuleSectionFooter: React.FC<React.ComponentProps<'div'>> = ({ children, ...props }) => (
  <div className={CLASS_MODULE_SECTION_FOOTER} {...props}>
    {children}
  </div>
);
