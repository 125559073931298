import { Log, Pagination } from 'interface/audit/audit.interface';
import { User, ServiceAccount } from 'interface/auth/auth.interface';

export type AuditState = {
  users: User[];
  serviceAccounts: ServiceAccount[];
  logs: Log[];
  pagination: Pagination;
  isLoading: boolean;
  isAuditInitialized: boolean;
};

export enum AuditActionType {
  SET_AUDIT_LOG_DATA = 'SET_AUDIT_LOG_DATA',
  SET_AUDIT_PAGINATION_DATA = 'SET_AUDIT_PAGINATION_DATA',
  SET_AUDIT_USERS_DATA = 'SET_AUDIT_USERS_DATA',
  SET_AUDIT_SERVICE_ACCOUNTS_DATA = 'SET_AUDIT_SERVICE_ACCOUNTS_DATA',
  SET_AUDIT_LOADING = 'SET_AUDIT_LOADING',
  SET_AUDIT_INITIALIZED = 'SET_AUDIT_INITIALIZED',
}

export type SetAuditUsers = {
  type: AuditActionType.SET_AUDIT_USERS_DATA;
  payload: User[];
};

export type SetAuditServiceAccounts = {
  type: AuditActionType.SET_AUDIT_SERVICE_ACCOUNTS_DATA;
  payload: ServiceAccount[];
};

export type SetAuditLogData = {
  type: AuditActionType.SET_AUDIT_LOG_DATA;
  payload: Log[];
};

export type SetAuditPaginationData = {
  type: AuditActionType.SET_AUDIT_PAGINATION_DATA;
  payload: Pagination;
};

export type SetAuditLoading = {
  type: AuditActionType.SET_AUDIT_LOADING;
  payload: boolean;
};

export type SetAuditInitialized = {
  type: AuditActionType.SET_AUDIT_INITIALIZED;
  payload: boolean;
};
