export enum ConfirmationModalTypes {
  PUBLISH = 'publish',
  DISCARD = 'discard',
}

export type ConfirmationDialogProps = {
  theme: ConfirmationModalTypes;
  icon: JSX.Element;
  title: string;
  description: string;
  primaryAction: {
    value: string;
    onClickHandler?: () => void;
  };
  secondaryAction: {
    value: string;
    onClickHandler?: () => void;
  };
  confirmationInput?: {
    placeholderText: string;
    matchText: string;
  };
  className?: string;
};
