import { useCallback, useMemo, useState } from 'react';
import { UseInfiniteQueryResult, useInfiniteQuery } from '@tanstack/react-query';
import { Pagination } from 'interface/shared/api.interface';
import merchantService from 'services/api/merchants.api';
import { Merchant } from 'pages/MerchantPortal/merchant-portal.types';

type PaginationProps = {
  totalPageNumber: number;
  currentPageNumber: number;
  rowsPerPageOptions: number[];
  currentRowsPerPage: number;
  handlePageChange: (page: number) => void;
};

type UseMerchantsResult = {
  paginationProps: PaginationProps;
  isLoading: boolean;
  isError: boolean;
  isFetching: boolean;
  merchants: Merchant[];
};

type MerchantsData = {
  merchants: Merchant[];
  pagination: Pagination;
};

export default function useMerchants(includeOffers = false, initialOffset = 0, limit = 10): UseMerchantsResult {
  const queryKey = ['merchants', { includeOffers, offset: initialOffset, limit }];

  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const fetchMerchants = async (offset = 0, limit = 10) => {
    const { data: merchants, pagination } = await merchantService.getMerchants({
      includeOffers,
      limit,
      offset,
    });

    return {
      merchants,
      pagination,
    };
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    fetchPreviousPage,
    hasPreviousPage,
    isLoading,
    isFetching,
    isError,
  }: UseInfiniteQueryResult<MerchantsData> = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam = initialOffset }) => fetchMerchants(pageParam, limit),
    getNextPageParam: (currentPage) => {
      const { totalRecords, offset, limit } = currentPage.pagination;
      const nextOffset = offset + limit;
      return nextOffset < totalRecords ? nextOffset : undefined;
    },
    getPreviousPageParam: (currentPage) => {
      const { offset, limit } = currentPage.pagination;
      const previousOffset = offset - limit;
      return previousOffset > 0 ? previousOffset : undefined;
    },
    refetchOnWindowFocus: false,
  });

  const totalPageNumber = Math.ceil((data?.pages[0]?.pagination?.totalRecords || 0) / limit);
  const currentPageData = useMemo(() => {
    return data?.pages[currentPageNumber - 1]?.merchants || [];
  }, [data, currentPageNumber]);

  const handlePageChange = useCallback(
    (page: number) => {
      if (hasNextPage && page > currentPageNumber) {
        fetchNextPage();
      } else if (hasPreviousPage && page < currentPageNumber) {
        fetchPreviousPage();
      }
      setCurrentPageNumber(page);
    },
    [currentPageNumber, fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage],
  );

  const paginationProps = useMemo(
    () => ({
      totalPageNumber,
      currentPageNumber,
      rowsPerPageOptions: [10],
      currentRowsPerPage: 10,
      handlePageChange,
    }),
    [currentPageNumber, handlePageChange, totalPageNumber],
  );

  return {
    paginationProps,
    isLoading,
    isError,
    isFetching,
    merchants: currentPageData,
  };
}
