/* eslint-disable @typescript-eslint/no-explicit-any */
import AuditApi from 'services/api/audit.api';
import AuthAPI from 'services/api/auth.api';
import { Log, Pagination } from 'interface/audit/audit.interface';
import {
  SetAuditLogData,
  SetAuditPaginationData,
  SetAuditUsers,
  SetAuditServiceAccounts,
  SetAuditLoading,
  SetAuditInitialized,
} from './audit.type';
import { AuditActionType } from './audit.type';
import { mergeUserFilters } from 'store/helper';
import { User, ServiceAccount } from 'interface/auth/auth.interface';

import { isEqual } from 'lodash';

const auditAPI = new AuditApi();

const authAPI = new AuthAPI();

export function fetchAuditLogs(pagination: Pagination, filter: any) {
  return (dispatch: any, getState: any) => {
    const state = getState();
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams();

      params.append('limit', pagination.limit.toString());
      params.append('offset', pagination.offset.toString());
      params.append('sort', pagination.sort);
      params.append('sortBy', pagination.sortBy);
      params.append('start', filter.start.toString());
      params.append('end', filter.end.toString());
      filter.action.forEach((action: string) => {
        params.append('action', action);
      });
      filter.eventType.forEach((type: string) => {
        params.append('eventType', type);
      });

      const userIds: string[] = mergeUserFilters(filter.userSearchIds, filter.userRoleIds);
      userIds.forEach((userId: string) => {
        params.append('userId', userId);
      });

      dispatch(setAuditLoading(true));
      auditAPI
        .getAudit(params)
        .then((res: any) => {
          dispatch(setAuditLogData(res.logs));
          if (!state.audit.isAuditInitialized && (!res.logs || !res.logs.length)) {
            dispatch(setAuditInitialized(true));
          }
          if (!isEqual(pagination, res.pagination)) {
            dispatch(setAuditPaginationData(res.pagination));
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch((err: any) => {
          reject();
        })
        .finally(() => dispatch(setAuditLoading(false)));
    });
  };
}

function setAuditUsers(payload: User[]): SetAuditUsers {
  return {
    type: AuditActionType.SET_AUDIT_USERS_DATA,
    payload,
  };
}

function setAuditServiceAccounts(payload: ServiceAccount[]): SetAuditServiceAccounts {
  return {
    type: AuditActionType.SET_AUDIT_SERVICE_ACCOUNTS_DATA,
    payload,
  };
}

export function fetchUsers() {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams();
      params.append('min', '10');
      params.append('max', '21');
      authAPI
        .getUsers(params)
        .then((res: { data: User[] }) => {
          dispatch(setAuditUsers(res.data));
        })
        .catch(() => {
          reject();
        });
    });
  };
}

export function fetchServiceAccounts() {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams();
      authAPI
        .getServiceAccounts(params)
        .then((res: { data: ServiceAccount[] }) => {
          dispatch(setAuditServiceAccounts(res.data));
        })
        .catch(() => {
          reject();
        });
    });
  };
}

function setAuditLogData(payload: Log[]): SetAuditLogData {
  return {
    type: AuditActionType.SET_AUDIT_LOG_DATA,
    payload,
  };
}

function setAuditLoading(payload: boolean): SetAuditLoading {
  return {
    type: AuditActionType.SET_AUDIT_LOADING,
    payload,
  };
}

function setAuditInitialized(payload: boolean): SetAuditInitialized {
  return {
    type: AuditActionType.SET_AUDIT_INITIALIZED,
    payload,
  };
}

export function setAuditPaginationData(payload: Pagination): SetAuditPaginationData {
  return {
    type: AuditActionType.SET_AUDIT_PAGINATION_DATA,
    payload,
  };
}
