import './EditMerchant.scss';

import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import MerchantForm from 'components/MerchantPortal/MerchantForm/MerchantForm';
import { MerchantFormValues, MerchantsRouteParams } from '../merchant-portal.types';
import useConfirmModal from 'hooks/useConfirmModal';
import IconPublishChanges from 'components/Shared/Icons/IconPublishChanges';
import { ConfirmationDialogProps, ConfirmationModalTypes } from 'components/Shared/shared.types';
import UnsavedPrompt from 'components/Shared/UnsavedPrompt/UnsavedPrompt';
import { FlightButton } from '@flybits/design-system';
import { useHistory, useParams } from 'react-router-dom';
import { MERCHANT_FORM_INIT_VALS } from '../merchant-portal.constants';
import { mapMerchantDataToFormValues, mapMerchantFormValuesToData } from '../merchant-portal.helpers';
import useMerchantCategoriesMock from 'hooks/useMerchantCategoriesMock';
import { useThunkDispatch as useDispatch } from 'hooks/reduxHooks';
import useDelayedLoadingState from 'hooks/useDelayedLoading';
import useMerchant from 'hooks/useMerchant';

const MAIN_CLASS = 'edit-merchant';
const CLASSES = {
  HEADER: `${MAIN_CLASS}__header`,
  TITLE: `${MAIN_CLASS}__header__title`,
  GUTTER: `${MAIN_CLASS}__header__gutter`,
  BODY: `${MAIN_CLASS}__body`,
  BREADCRUMBS: `${MAIN_CLASS}__body__breadcrumbs`,
  CONTAINER: `${MAIN_CLASS}__body__container`,
  LOADING_BAR: `${MAIN_CLASS}__loading-bar`,
  LOADING_WRAPPER: `${MAIN_CLASS}__loading-bar__wrapper`,
};

const confirmationDialogProps: ConfirmationDialogProps = {
  theme: ConfirmationModalTypes.PUBLISH,
  icon: <IconPublishChanges />,
  title: 'Publish Changes',
  description: `Are you sure you want to commit these changes to the merchant profile?`,
  primaryAction: {
    value: 'Publish changes',
  },
  secondaryAction: {
    value: 'Cancel',
  },
};
const EditMerchant = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pid: projectId, merchantid } = useParams<MerchantsRouteParams>();
  const {
    merchant,
    isMerchantLoading,
    isMerchantError,
    handleUpdateMerchant,
    isMerchantUpdating,
    isMerchantUpdated,
    merchantUpdateError,
  } = useMerchant(merchantid ?? '', true);
  const isDelayedSubmitting = useDelayedLoadingState(isMerchantUpdating);
  const { merchantCategories, isMerchantCategoriesLoading } = useMerchantCategoriesMock();
  const [merchantData, setMerchantData] = useState<MerchantFormValues>(MERCHANT_FORM_INIT_VALS);
  const [isMerchantFormDirty, setMerchantFormDirty] = useState(false);
  const [formSubmitError, setFormSubmitError] = useState(false);
  const [SaveMerchantConfirmModal, showMerchantConfirmModal] = useConfirmModal(confirmationDialogProps);

  const handleOnFormSubmit = useCallback(
    async (values: MerchantFormValues) => {
      try {
        if (await showMerchantConfirmModal()) {
          const updatedMerchantValues = await mapMerchantFormValuesToData(values);
          handleUpdateMerchant({ ...updatedMerchantValues, offers: merchant?.offers || [] });
          setFormSubmitError(false);
        }
      } catch {
        setFormSubmitError(true);
      }
    },
    [showMerchantConfirmModal, handleUpdateMerchant, merchant?.offers],
  );

  useEffect(() => {
    if (isMerchantError) {
      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: {
          title: 'Error',
          content: 'Something went wrong! Please try again.',
          type: 'error',
        },
      });
      history.push(`/project/${projectId}/merchants`);
    }

    if (merchant && !isMerchantError && !isMerchantLoading) {
      setMerchantData(mapMerchantDataToFormValues(merchant));
    }
  }, [merchant, isMerchantError, isMerchantLoading, dispatch, history, projectId]);

  useLayoutEffect(() => {
    if (!isDelayedSubmitting) {
      if (isMerchantUpdated) {
        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: { content: 'Merchant details successfully saved.', type: 'success' },
        });
        history.push(`/project/${projectId}/merchants`);
      }

      if (formSubmitError || merchantUpdateError) {
        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: {
            title: 'Error',
            content: merchantUpdateError || 'Merchant details could not be saved.',
            type: 'error',
          },
        });
      }
    }
  }, [isMerchantUpdated, isDelayedSubmitting, history, projectId, dispatch, formSubmitError, merchantUpdateError]);

  return (
    <>
      <div className={MAIN_CLASS}>
        <header className={CLASSES.HEADER}>
          <FlightButton
            iconLeft="clear"
            theme="link"
            ariaLabel="navigate back to merchant list"
            onClick={() => history.push(`/project/${projectId}/merchants`)}
          />
          <div className={CLASSES.TITLE}>Edit Merchant Information</div>
          <div className={CLASSES.GUTTER}></div>
        </header>
        {isMerchantLoading || isMerchantCategoriesLoading || isDelayedSubmitting ? (
          <div className={CLASSES.LOADING_WRAPPER} aria-label="merchant is loading">
            <div className={CLASSES.LOADING_BAR}>
              <div></div>
            </div>
          </div>
        ) : (
          <div className={CLASSES.BODY}>
            <section className={CLASSES.CONTAINER}>
              <MerchantForm
                data={merchantData}
                categories={merchantCategories ?? []}
                onSubmit={handleOnFormSubmit}
                setDirty={setMerchantFormDirty}
                isEditMode
              />
            </section>
          </div>
        )}
      </div>
      {SaveMerchantConfirmModal()}
      <UnsavedPrompt
        when={!isMerchantUpdated && isMerchantFormDirty}
        unblockPaths={['/edit-merchant']}
        dialogProps={{
          description: 'This will undo all the information you have entered since you started.',
        }}
      />
    </>
  );
};

export default EditMerchant;
