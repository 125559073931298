import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import './CreateMerchantOffer.scss';
import { FlightButton } from '@flybits/design-system';
import Breadcrumbs from 'components/Shared/Breadcrumbs/Breadcrumbs';
import OfferForm from 'components/MerchantPortal/OfferForm/OfferForm';
import { MerchantsRouteParams, OfferFormValues, OfferScheduleFormValues } from '../merchant-portal.types';
import OfferScheduleForm from 'components/MerchantPortal/OfferForm/OfferScheduleForm';
import OfferReview from 'components/MerchantPortal/OfferReview/OfferReview';
import UnsavedPrompt from 'components/Shared/UnsavedPrompt/UnsavedPrompt';
import OfferSubmitted from 'components/MerchantPortal/OfferSubmitted/OfferSubmitted';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import merchantService from 'services/api/merchants.api';
import { isAxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import useDelayedLoadingState from 'hooks/useDelayedLoading';
import { capitalizeFirstCharacter } from 'helpers/common.helper';
import useMerchantPortalSettings from 'hooks/useMerchantPortalSettings';
import useMerchant from 'hooks/useMerchant';
import { OFFER_FORM_INIT_VALS } from 'pages/MerchantPortal/merchant-portal.constants';
import { getOfferScheduleInitValues } from 'pages/MerchantPortal/merchant-portal.helpers';

const CREATE_MERCHANT_OFFER = 'create-merchant-offer';
const CLASSES = {
  HEADER: `${CREATE_MERCHANT_OFFER}__header`,
  INFO: `${CREATE_MERCHANT_OFFER}__header__info`,
  TITLE: `${CREATE_MERCHANT_OFFER}__header__title`,
  BUTTON: `${CREATE_MERCHANT_OFFER}__header__button`,
  SUBTITLE: `${CREATE_MERCHANT_OFFER}__header__subtitle`,
  BODY: `${CREATE_MERCHANT_OFFER}__body`,
  BREADCRUMBS: `${CREATE_MERCHANT_OFFER}__body__breadcrumbs`,
  CONTAINER: `${CREATE_MERCHANT_OFFER}__body__container`,
};
// NOTE: These are EXAMPLE icons. Please update to match with designs when implementing
const BREADCRUMB_ITEMS = [
  { icon: null, forceOutline: true, text: 'Enter offer information' },
  { icon: null, forceOutline: true, text: 'Enter schedule and budget' },
  { icon: null, forceOutline: true, text: 'Review and confirm' },
];

const CreateMerchantOffer = function () {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { pid: projectId, merchantid } = useParams<MerchantsRouteParams>();
  const { merchant: selectedMerchant } = useMerchant(merchantid ?? '');
  const [selectedStep, setSelectedStep] = useState(1);
  const [selectedMerchantId, setSelectedMerchantId] = useState(merchantid);
  const [submitErrorMessage, setSubmitErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isDelayedSubmitting = useDelayedLoadingState(isSubmitting);
  // ignoring the loading for now
  const { defaultJourneyTemplateId: journeyTemplateId } = useMerchantPortalSettings(selectedMerchantId ?? '');
  const [offerData, setOfferData] = useState<OfferFormValues>({ ...OFFER_FORM_INIT_VALS });
  const [offerScheduleData, setOfferScheduleData] = useState<OfferScheduleFormValues>({
    ...getOfferScheduleInitValues(),
  });
  const [isOfferFormDirty, setOfferFormDirty] = useState(false);
  const [isOfferScheduleFormDirty, setOfferScheduleFormDirty] = useState(false);

  useEffect(() => {
    if (selectedMerchant?.name) {
      setOfferData((data) => ({ ...data, merchantName: selectedMerchant.name }));
    }
  }, [selectedMerchant]);

  useLayoutEffect(() => {
    setSelectedMerchantId(merchantid);
    if (!isDelayedSubmitting && submitErrorMessage) {
      setSelectedStep(3);
      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: {
          title: 'Error',
          content: `The offer "${offerData.title}" could not be created. ${capitalizeFirstCharacter(
            submitErrorMessage,
          )}`,
          type: 'error',
        },
      });
    }
  }, [dispatch, isDelayedSubmitting, merchantid, offerData.title, submitErrorMessage]);

  const handleOnReviewSubmit = useCallback(async () => {
    if (!selectedMerchantId) return;
    setIsSubmitting(true);
    setSubmitErrorMessage('');
    setSelectedStep(4);
    try {
      const payload = {
        title: offerData.title,
        description: offerData.description,
        spendThreshold: parseFloat(String(offerData.spendThreshold).substring(1).replaceAll(',', '')),
        earnCap: parseFloat(String(offerData.earnCap).substring(1).replaceAll(',', '')),
        notes: offerData.verification?.notes || '',
        desiredStartDate: offerScheduleData.startDate,
        desiredEndDate: offerScheduleData.endDate,
        targetBudget: parseFloat(String(offerScheduleData.targetBudget).substring(1).replaceAll(',', '')),
        budgetNotes: offerScheduleData.budgetNote || '',
        journeyTemplateId,
      };
      await merchantService.createOffer(selectedMerchantId, payload);
      await queryClient.invalidateQueries({ queryKey: ['merchants'] });
      setIsSubmitting(false);
    } catch (e: any) {
      let errorMessage = isAxiosError(e) || e instanceof Error ? e.message : 'unknown';
      // handles better error message from backend
      if (e?.response?.data?.error?.exceptionMessage) {
        errorMessage = e.response.data.error.exceptionMessage;
      }
      setSubmitErrorMessage(errorMessage);
      setIsSubmitting(false);
    }
  }, [offerData, offerScheduleData, queryClient, selectedMerchantId, journeyTemplateId]);
  const unsavedPromptCondition = selectedStep < 4 && (isOfferFormDirty || isOfferScheduleFormDirty);
  const handleResetForm = useCallback(() => {
    setOfferData({ ...OFFER_FORM_INIT_VALS });
    setOfferScheduleData({ ...getOfferScheduleInitValues() });
    setSelectedStep(1);
  }, []);

  return (
    <div className={CREATE_MERCHANT_OFFER}>
      <header className={CLASSES.HEADER}>
        <FlightButton
          iconLeft="clear"
          theme="link"
          ariaLabel="navigate back to merchant list"
          onClick={() => history.push(`/project/${projectId}/merchants`)}
        />
        <div className={CLASSES.TITLE}>Submit a new Merchant Offer</div>
        <div className={`${CLASSES.HEADER}__gutter`}></div>
      </header>
      <div className={CLASSES.BODY}>
        {selectedStep !== 4 ? (
          <>
            <section className={CLASSES.BREADCRUMBS}>
              <Breadcrumbs items={BREADCRUMB_ITEMS} selectedStep={selectedStep} />
            </section>
            {selectedStep === 1 && (
              <section className={CLASSES.CONTAINER}>
                <OfferForm
                  onSubmit={(values: OfferFormValues) => {
                    setOfferData(values);
                    setSelectedStep(2);
                  }}
                  data={offerData}
                  setDirty={setOfferFormDirty}
                />
              </section>
            )}
            {selectedStep === 2 && (
              <section className={CLASSES.CONTAINER}>
                <OfferScheduleForm
                  onSubmit={(values: OfferScheduleFormValues) => {
                    setOfferScheduleData(values);
                    setSelectedStep(3);
                  }}
                  onPrevious={() => setSelectedStep(1)}
                  data={offerScheduleData}
                  setDirty={setOfferScheduleFormDirty}
                />
              </section>
            )}
            {selectedStep === 3 && (
              <section className={CLASSES.CONTAINER}>
                <OfferReview
                  onSubmit={handleOnReviewSubmit}
                  onPrevious={() => setSelectedStep(2)}
                  data={{ offer: offerData, schedule: offerScheduleData }}
                />
              </section>
            )}
          </>
        ) : (
          <section className={CLASSES.CONTAINER}>
            <OfferSubmitted
              data={{ offer: offerData, schedule: offerScheduleData }}
              onReset={handleResetForm}
              isSubmitting={isDelayedSubmitting}
            />
          </section>
        )}
      </div>
      <UnsavedPrompt
        when={unsavedPromptCondition}
        unblockPaths={['/create-offer']}
        dialogProps={{
          description: 'This will undo all the information you have entered since you started.',
        }}
      />
    </div>
  );
};

export default CreateMerchantOffer;
