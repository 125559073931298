import React from 'react';

import './ZoneModuleTabs.scss';
import { FlightTooltip } from '@flybits/design-system';

const MAIN_CLASS = 'zm-tabs';
const CLASSES = {
  TABS: `${MAIN_CLASS}__container`,
  TAB: `${MAIN_CLASS}__tab`,
  ACTIVE_TAB: `${MAIN_CLASS}__tab--active`,
  BODY: `${MAIN_CLASS}__content`,
};

export type ZoneAndModuleTab = {
  key: string;
  name: string;
  title: string;
  isDisabled?: boolean;
  disabledDescription?: string;
};

type ZoneAndModuleTabProps = {
  tabs: ZoneAndModuleTab[];
  activeIdx: number;
  children: React.ReactNode;
  secondaryAction?: () => null;
  primaryAction?: () => null;
};

function ZoneModuleTabs({ tabs, activeIdx, children }: ZoneAndModuleTabProps) {
  return (
    <div className={MAIN_CLASS}>
      {!!tabs.length && (
        <div className={CLASSES.TABS}>
          {tabs.map((t, i) =>
            t.isDisabled ? (
              <FlightTooltip
                key={`zm-tab-${t.key}`}
                isEnabled={t.isDisabled}
                description={t.disabledDescription}
                direction="bottom"
              >
                <div className={`${CLASSES.TAB} ${i === activeIdx ? CLASSES.ACTIVE_TAB : ''}`}>
                  <span>{i + 1}</span>
                  {t.name}
                </div>
              </FlightTooltip>
            ) : (
              <div key={`zm-tab-${t.key}`} className={`${CLASSES.TAB} ${i === activeIdx ? CLASSES.ACTIVE_TAB : ''}`}>
                <span>{i + 1}</span>
                {t.name}
              </div>
            ),
          )}
        </div>
      )}
      <div className={CLASSES.BODY}>{children}</div>
    </div>
  );
}

export default ZoneModuleTabs;
