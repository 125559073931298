import React, { useEffect, useState } from 'react';
import './MerchantPortal.scss';
import MerchantEmpty from 'components/MerchantPortal/MerchantEmpty/MerchantEmpty';
import MerchantList from 'components/MerchantPortal/MerchantList/MerchantList';
import useMerchants from 'hooks/useMerchants';
import { FlightToggleSwitch } from '@flybits/design-system';
import MockMerchantList from 'components/MerchantPortal/MerchantList/MockMerchantList';
import { MERCHANT_PORTAL, MERCHANT_PORTAL_CLASSES } from 'pages/MerchantPortal/merchant-portal.constants';

const MerchantPortal: React.FC<React.ComponentProps<'div'>> = () => {
  const { isFetching, isLoading, merchants } = useMerchants();

  const [isMock, setMock] = useState(false);

  useEffect(() => {
    document.title = 'Merchant Portal | Experience Studio @ Flybits';
  }, []);

  return (
    <div className={MERCHANT_PORTAL}>
      {!isFetching && !isLoading && !merchants.length ? (
        <div className={MERCHANT_PORTAL_CLASSES.EMPTY_CONTAINER}>
          <MerchantEmpty />
        </div>
      ) : (
        <div className={MERCHANT_PORTAL_CLASSES.BODY}>{!isMock ? <MerchantList /> : <MockMerchantList />}</div>
      )}
      {/* Currently, the BE response for merchants doesn't contain any `offers` associated with them
      So, this toggle is added to allow the users to test `View Offers` flow by switching to mock merchants data
      ToDo: Remove this after BE response includes `offers` */}
      {!isFetching && !isLoading && !!merchants.length && (
        <div className={MERCHANT_PORTAL_CLASSES.MOCK_TOGGLE}>
          <FlightToggleSwitch
            label={isMock ? 'Using mock data now' : 'Using real data now'}
            checked={isMock}
            onChange={(toggleValue: boolean) => setMock(toggleValue)}
          />
        </div>
      )}
    </div>
  );
};
export default MerchantPortal;
