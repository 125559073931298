import { useCallback, useEffect, useState } from 'react';
import { useThunkDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/reduxHooks';
import { fetchTemplate, fetchInstance, duplicateInstance } from 'store/journey/journey.thunk';
import { JourneyException } from 'types/errors';
import SettingAPI from 'services/api/setting.api';
import merchantService from 'services/api/merchants.api';
import {
  mapMerchantOfferToContentCreationPayload,
  mapMerchantOfferToRulePayload,
} from 'pages/MerchantPortal/merchant-portal.helpers';
import { ExperienceTemplate } from 'interface/experience/experience.interface';
import { AppThunkDispatch } from 'store/store';
import { createContentInstances } from 'store/content/content.thunk';
import { CONTENT_ACTION_TYPES } from 'store/content/content.type';
import { saveRulesThunk } from 'store/rule/rule.thunk';
import { RuleActionTypes } from 'store/rule/rule.type';
import { Loader } from '@googlemaps/js-api-loader';

const mapsLoader = new Loader({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY ?? '',
  version: 'weekly',
  libraries: ['geocoding', 'places', 'geometry'],
});
const settingAPI = new SettingAPI();
// TODO: Maybe move this to its own ecosystem?
const fetchParentEntity = async (
  entityId: string,
  entityType: string,
  journeyTemplate: ExperienceTemplate,
  projectId: string,
  dispatch: AppThunkDispatch,
) => {
  switch (entityType) {
    case 'offer':
      try {
        // TODO: move project fetch call in app.tsx to react-query and use that here.
        const projectSubdomain = (await settingAPI.getProjectSettings(projectId))?.Subdomain.split('.')[0];
        // TODO: mcfudging it rn because there ain't any single fetch endpoints
        const merchants = await merchantService.getMerchants({ limit: 999, includeOffers: true });
        const merchant = merchants.data?.find((m) => m.offers?.find((o) => o.id === entityId));
        const offer = merchant?.offers?.find((o) => o.id === entityId);
        const contentRefId = journeyTemplate.steps?.[0].actions.find(
          (a) => a.actionType === CONTENT_ACTION_TYPES.INSTANCE,
        )?.refId;

        if (!merchant || !offer) return;
        // big fudge on the localizations for now
        const address = `${merchant.addressLine}, ${merchant.addressCity}, ${merchant.addressState}, USA, ${merchant.addressZipCode}`;
        await mapsLoader.importLibrary('geocoding');
        const geocoder = new google.maps.Geocoder();
        const { results: geoCodeResponse } = await geocoder.geocode({ address });
        const location = {
          lat: geoCodeResponse[0]?.geometry?.location?.lat() ?? 0,
          lng: geoCodeResponse[0]?.geometry?.location?.lng() ?? 0,
          metadata: {
            address: geoCodeResponse[0]?.formatted_address ?? address,
            placeId: geoCodeResponse[0]?.place_id,
          },
        };
        const saveRulePayload = mapMerchantOfferToRulePayload(merchant, journeyTemplate, projectSubdomain);
        const contentPayload = mapMerchantOfferToContentCreationPayload(
          merchant,
          offer,
          journeyTemplate,
          'en',
          projectSubdomain,
          location,
        );
        if (saveRulePayload) {
          dispatch(saveRulesThunk(saveRulePayload));
          dispatch({
            type: RuleActionTypes.UPDATE_RULE_TRIGGER,
            payload: { stepIdx: 0, omitRule: 'default', isOptionalRuleEnabled: true },
          });
        }
        if (contentPayload && contentRefId) {
          // will not handle no-prototype in journey template case
          const createdContents = await dispatch(createContentInstances([contentPayload], [contentRefId]));
          if (!createdContents?.[0]) return;
          dispatch({
            type: 'UPDATE_CONTENT',
            payload: {
              [contentRefId]: createdContents[0],
            },
          });
        }
      } catch (e) {}
      return;
    default:
  }
};

/**
 * Selects the currently active journey instance on the canvas.
 * If it has not been fetched, fetches the instance and/or template.
 * */
export default function useJourneyInstance(
  journeyId: string,
  isTemplate: boolean,
  createFromId = '',
  parentEntityId = '',
  parentEntityType = '',
  projectId = '',
) {
  const journeyInstance = useSelector((state) => state.te.journey);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<JourneyException | null>(null);

  const fetch = useCallback(
    async (showLoading = true) => {
      setLoading(showLoading);

      try {
        // uninitialized journey and createFromId => duplicate
        if (!journeyInstance.id && createFromId) {
          const _journeyInstance = await dispatch(duplicateInstance(createFromId, journeyId));
          if (_journeyInstance?.templateId) {
            dispatch(fetchTemplate(_journeyInstance.templateId));
          }
          // uninitialized journey and isTemplate => create from template
        } else if (!journeyInstance.id && isTemplate === true) {
          // only merge for template for now
          const journeyTemplate = await dispatch(fetchTemplate(journeyId, false));

          if (parentEntityId && parentEntityType) {
            await fetchParentEntity(parentEntityId, parentEntityType, journeyTemplate, projectId, dispatch);
            dispatch({
              type: 'UPDATE_JOURNEY',
              payload: {
                parentEntityId,
                parentEntityType,
              },
            });
          }
          // edit
        } else {
          const _journeyInstance = await dispatch(fetchInstance(journeyInstance.id || journeyId));
          if (_journeyInstance?.templateId) {
            dispatch(fetchTemplate(_journeyInstance.templateId));
          }
        }
      } catch (e) {
        setError(e as JourneyException);
      } finally {
        setLoading(false);
      }
    },
    [isTemplate, journeyId, journeyInstance, dispatch, createFromId, parentEntityId, parentEntityType, projectId],
  );

  useEffect(() => {
    if (!journeyId || (!!journeyInstance.steps.length && !journeyInstance.id) || journeyId === journeyInstance.id)
      return;

    fetch();
  }, [journeyId, journeyInstance, fetch]);

  return { journeyInstance, isLoading, fetch, error };
}
