import React, { useEffect, useState } from 'react';
import { useAppSelector as useSelector } from 'hooks/reduxHooks';

import {
  FlightSearch,
  FlightDatePicker,
  FlightDropdown,
  FlightButton,
  FlightCollapsible,
  FlightCheckbox,
  FlightTable,
  FlightDateRangePicker,
  FlightLabel,
} from '@flybits/design-system';

import { ReactComponent as ContentEmptyAudit } from 'assets/images/audit-data-missing.svg';

import EmptyAuditHistory from 'assets/images/audit-history-empty.svg';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { isEqual } from 'lodash';

import useActions from 'hooks/useActions';
import * as AuditActions from 'store/settings/audit/audit.action';

import { Log } from 'interface/audit/audit.interface';

import './Audit.scss';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { TExperienceCanvasRouteParams } from 'pages/ExperienceCanvas/types';

import queryString from 'query-string';

type CollapsibleItems = {
  name: string;
  key: number | string;
  disabled: boolean;
  content: JSX.Element;
};

type TableData = {
  timestamp?: JSX.Element;
  user?: JSX.Element;
  action?: JSX.Element;
  entity_type?: JSX.Element;
  service?: JSX.Element;
  description?: JSX.Element;
};

const userFilterOptions = [
  // { label: 'App User', value: 'App User' },
  // { label: 'Viewer', value: 'Viewer' },
  { label: 'Creator', value: 'Creator' },
  { label: 'Publisher', value: 'Publisher' },
  { label: 'Permission Manager', value: 'Permission Manager' },
  { label: 'Owner', value: 'Owner' },
  { label: 'Grantor', value: 'Grantor' },
];

const actionFilterOptions = [
  { label: 'Created', value: 'created' },
  { label: 'Updated', value: 'updated' },
  { label: 'Deleted', value: 'deleted' },
  { label: 'Activated', value: 'activated' },
  { label: 'Deactivated', value: 'deactivated' },
  { label: 'Broadcasted', value: 'broadcasted' },
  { label: 'Modified', value: 'modified' },
  { label: 'Undrafted', value: 'undrafted' },
];

const typeFilterOptions = [
  { label: 'Entity', value: 'entity' },
  { label: 'System', value: 'system' },
];

const tableHeaders = [
  {
    name: '',
    key: 'key',
    isVisible: false,
    hideTooltip: true,
  },
  {
    name: 'Timestamp',
    key: 'timestamp',
    isVisible: true,
    hideTooltip: true,
    isSortable: true,
  },
  {
    name: 'User',
    key: 'user',
    isVisible: true,
    hideTooltip: true,
    isSortable: false,
  },
  {
    name: 'Action',
    key: 'action',
    isVisible: true,
    hideTooltip: true,
    isSortable: true,
  },
  {
    name: 'Entity',
    key: 'entity_type',
    isVisible: true,
    hideTooltip: true,
    isSortable: true,
  },
  {
    name: 'Service',
    key: 'service',
    isVisible: true,
    hideTooltip: true,
    isSortable: true,
  },
  {
    name: 'Description',
    key: 'description',
    isVisible: true,
    hideTooltip: true,
    isSortable: false,
  },
];

export default function Audit() {
  const user = useSelector((state) => state.auth.user);
  const isAuditLoading = useSelector((state) => state.audit.isLoading);
  const isAuditInitialized = useSelector((state) => state.audit.isAuditInitialized);
  const history = useHistory();
  const today = new Date();
  const todayEpoch = (today.getTime() - today.getMilliseconds()) / 1000;
  const users = useSelector((state) => state.audit.users);
  const serviceAccounts = useSelector((state) => state.audit.serviceAccounts);

  const queryParams = queryString.parse(history.location?.search);

  const [filters, setFilters] = useState<any>({
    search: queryParams.search! ? String(queryParams.search) : '',
    searchExact: queryParams.searchExact! === 'true' ? Boolean(queryParams.searchExact) : false,
    sortby: queryParams.sortby! ? String(queryParams.sortby) : 'createdAt',
    sortorder: queryParams.sortorder! ? String(queryParams.sortorder) : 'desc',
    limit: queryParams.limit! ? Number(queryParams.limit) : 10,
    page: queryParams.page! ? Number(queryParams.page) : 1,
    status: !isEmpty(queryParams.status) ? queryParams.status : [],
    type: !isEmpty(queryParams.type) ? queryParams.type : [],
    label: !isEmpty(queryParams.label) ? queryParams.label : [],
    startdate: queryParams.startdate! ? Number(queryParams.startdate) : 0,
    enddate: queryParams.enddate! ? Number(queryParams.enddate) : 0,
    start: 0,
    end: todayEpoch,
    action: [],
    userType: [],
    eventType: [],
    userSearchIds: [],
    userRoleIds: [],
  });
  const [isSingleDay, setIsSingleDay] = useState(false);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const auditAction = useActions(AuditActions);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { logs } = useSelector((state: any) => state.audit);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { totalRecords } = useSelector((state: any) => state.audit.pagination);
  const [pagination, setPagination] = useState({
    limit: 20,
    offset: 0,
    sort: 'desc',
    sortBy: 'timestamp',
  });

  const { pid: projectId } = useRouteMatch<TExperienceCanvasRouteParams>().params;
  const handleCreateExperience = () => {
    history.push(`/project/${projectId}/experiences/templates/library`);
  };

  const collapsibleItems: CollapsibleItems[] = [
    {
      name: 'User Role',
      key: 0,
      disabled: false,
      content: (
        <div>
          {userFilterOptions.map((option) => (
            <FlightCheckbox
              key={option.value}
              checkState={filters.userType.includes(option.value) ? 'SELECTED' : 'UNSELECTED'}
              onSelect={() => handleOnUserFilterSelect(option.value)}
              label={option.label}
            />
          ))}
        </div>
      ),
    },
    {
      name: 'Action',
      key: 1,
      disabled: false,
      content: (
        <div>
          {actionFilterOptions.map((option) => (
            <FlightCheckbox
              key={option.value}
              checkState={filters.action.includes(option.value) ? 'SELECTED' : 'UNSELECTED'}
              onSelect={() => handleOnActionFilterSelect(option.value)}
              label={option.label}
            />
          ))}
        </div>
      ),
    },
    {
      name: 'Type',
      key: 2,
      disabled: false,
      content: (
        <div>
          {typeFilterOptions.map((option) => (
            <FlightCheckbox
              key={option.value}
              checkState={filters.eventType.includes(option.value) ? 'SELECTED' : 'UNSELECTED'}
              onSelect={() => handleOnTypeFilterSelect(option.value)}
              label={option.label}
            />
          ))}
        </div>
      ),
    },
  ];

  const resetPagination = () => {
    setPagination({ limit: 20, offset: 0, sort: 'desc', sortBy: 'timestamp' });
  };

  const dateToEpoch = (date: Date) => {
    if (date == null) date = new Date(0);
    return (date.getTime() - date.getMilliseconds()) / 1000;
  };

  const levelToRole = (level: number) => {
    if (level >= 21) {
      return 'Grantor';
    } else if (level >= 20) {
      return 'Owner';
    } else if (level >= 17) {
      return 'Permission Manager';
    } else if (level >= 15) {
      return 'Publisher';
    } else if (level >= 10) {
      return 'Creator';
    } else if (level >= 5) {
      return 'Viewer';
    } else if (level >= 1) {
      return 'App User';
    }
    return 'Service Account';
  };

  const handleOnSearch = (value: string) => {
    let searchIds: string[] = [];
    if (value !== '') {
      const userIds = users
        .filter((user) => `${user.firstName} ${user.lastName}`.toLowerCase().includes(value.toLowerCase()))
        .map((user) => user.id);
      const saIds = serviceAccounts
        .filter((sa) => sa.name.toLowerCase().includes(value.toLowerCase()))
        .map((sa) => sa.id);
      searchIds = userIds.concat(saIds);
      if (searchIds.length < 1) {
        // to display no results found
        searchIds = [''];
      }
    }
    setFilters({ ...filters, userSearchIds: searchIds });
  };

  const handleOnSingleDatePick = (newDate: Date) => {
    resetPagination();
    setFilters({
      ...filters,
      start: dateToEpoch(newDate),
      end: dateToEpoch(newDate) + 86359, //86359 for 1 day in epoch
    });
  };

  const handleOnRangeDatePick = (newDate: Date[]) => {
    resetPagination();

    const newStartDate = newDate[0];
    let newEndDate = newDate[1];

    if (!newDate[1]) {
      newEndDate = new Date();
    }

    setFilters({
      ...filters,
      start: dateToEpoch(newStartDate),
      end: dateToEpoch(newEndDate),
    });
  };

  const handleOnChangeDateFormat = () => {
    setIsSingleDay(!isSingleDay);
  };

  const handleOnUserFilterSelect = (value: string) => {
    if (filters.userType.includes(value)) {
      const removeValue = filters.userType.filter((option: string) => option !== value);
      setFilters({ ...filters, userType: removeValue });
    } else {
      setFilters({ ...filters, userType: filters.userType.concat(value) });
    }
  };

  const handleOnActionFilterSelect = (value: string) => {
    if (filters.action.includes(value)) {
      const removeValue = filters.action.filter((option: string) => option !== value);
      setFilters({ ...filters, action: removeValue });
    } else {
      setFilters({ ...filters, action: filters.action.concat(value) });
    }
  };

  const handleOnTypeFilterSelect = (value: string) => {
    if (filters.eventType.includes(value)) {
      const removeValue = filters.eventType.filter((option: string) => option !== value);
      setFilters({ ...filters, eventType: removeValue });
    } else {
      setFilters({ ...filters, eventType: filters.eventType.concat(value) });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnHeaderSort = (header: any) => {
    let key = pagination.sortBy;
    let sort = 'DESC';

    if (header?.key === pagination.sortBy) {
      sort = pagination.sort === 'DESC' ? 'ASC' : 'DESC';
    } else {
      key = header?.key;
    }

    setPagination({
      ...pagination,
      sort,
      sortBy: key,
      offset: 0,
    });
  };

  const getTotalPageNumber = () => {
    return Math.ceil(totalRecords / pagination.limit);
  };

  const getCurrentPageNumber = () => {
    const totalPages = getTotalPageNumber();
    return totalPages + 1 - Math.ceil((totalRecords - pagination.offset) / pagination.limit);
  };

  const handleOnPageChange = (page: number) => {
    const nextOffset = page - 1;
    setPagination({
      ...pagination,
      offset: pagination.limit * nextOffset,
    });
  };

  const handleOnRowPerPageChange = (amount: number) => {
    setPagination({
      ...pagination,
      limit: amount,
      offset: 0,
    });
  };

  const formatAuditField = (str: string) => {
    return str
      .split('-')
      .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
      .join(' ');
  };

  useEffect(() => {
    document.title = 'Audit History | Experience Studio @ Flybits';
    // Prevent access from users that aren't grantors
    if (user && user?.level < 20) {
      history.push('/no-access');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      const roleToLevel: { [key: string]: number } = {
        'App User': 1,
        Viewer: 5,
        Creator: 10,
        Publisher: 15,
        'Permission Manager': 17,
        Owner: 20,
        Grantor: 21,
      };

      let roleIds: string[] = [];
      if (filters.userType.length > 0) {
        for (const role of filters.userType) {
          const level = roleToLevel[role];
          roleIds = roleIds.concat(users.filter((user) => user.level === level).map((user) => user.id));
        }
        if (roleIds.length < 1) {
          // to display no results found
          roleIds = [''];
        }
      }
      filters.userRoleIds = roleIds;
      await auditAction.fetchAuditLogs(pagination, filters);
    })();
  }, [pagination, filters, auditAction, users]);

  useEffect(() => {
    async () => {
      await auditAction.fetchUsers();
      await auditAction.fetchServiceAccounts();
    };
  }, [auditAction]);

  useEffect(() => {
    const _tableData: TableData[] = [];

    logs?.forEach((log: Log) => {
      _tableData.push({
        timestamp: (
          <span className="audit__content__body__table__content">
            {moment.unix(log.timestamp).utc().format('DD/MM/YYYY kk:mm:ss')}
          </span>
        ),
        user: (
          <div className="audit__content__body__table__content">
            <span className="audit__content__body__table__name">{log.userName}</span>
            <span>{levelToRole(log.userLevel)}</span>
          </div>
        ),
        action: <span className="audit__content__body__table__content">{formatAuditField(log.action)}</span>,
        entity_type: <span className="audit__content__body__table__content">{formatAuditField(log.entityType)}</span>,
        service: <span className="audit__content__body__table__content">{formatAuditField(log.service)}</span>,
        description: <span className="audit__content__body__table__content">{log.entityName}</span>,
      });
    });

    if (!isEqual(tableData, _tableData)) {
      setTableData(_tableData);
    }
  }, [logs, tableData]);

  const renderEmptyLogs = () => (
    <div className="audit__content__empty">
      <div className="audit__content__empty__image">
        <img src={EmptyAuditHistory} alt="getting started" />
      </div>
      <span className="audit__content__empty__text audit__content__empty__text--main">
        Looks like there is no audit history here
      </span>
      <span className="audit__content__empty__text audit__content__empty__text--sub">
        Audit history shows the users&lsquo; activities within your organization. This feature records the occurrence of
        an event, the time at which it occurred, the responsible user, and the impacted entity.{' '}
      </span>
      <span className="audit__content__empty__text audit__content__empty__text--explanation">
        How about start creating a new experience?
      </span>
      <span>
        <FlightButton
          className="audit__content__empty__create-experience-button"
          label="Create an experience"
          size="large"
          onClick={handleCreateExperience}
        />
      </span>
    </div>
  );

  return (
    <div className="audit">
      <div className="audit__header">
        <span className="audit__header__title">Audit History</span>
      </div>
      {!isAuditInitialized && !isAuditLoading && logs.length < 1 ? (
        // Empty Audit History Page
        renderEmptyLogs()
      ) : (
        // Audit History Table
        <div className="audit__content">
          <div className="audit__content__header">
            <FlightSearch
              className="audit__content__header__search"
              onSearch={handleOnSearch}
              placeholderText="Search by user name"
              label="Search by user name"
            />
            {isSingleDay ? (
              <FlightDatePicker
                className="audit__content__header__date-picker"
                selected={new Date(filters.end * 1000)}
                onSelect={handleOnSingleDatePick}
              />
            ) : (
              <FlightDateRangePicker
                className="audit__content__header__date-picker"
                isIncludeCustom
                value={filters.start ? [new Date(filters.start * 1000), new Date(filters.end * 1000)] : []}
                onChange={handleOnRangeDatePick}
              />
            )}
            <FlightCheckbox
              onSelect={handleOnChangeDateFormat}
              label="Specific Date"
              checkState={isSingleDay ? 'SELECTED' : 'UNSELECTED'}
            />
            <FlightDropdown
              className="audit__content__header__filter"
              maxWidth="268px"
              trigger={
                <FlightButton
                  isPropagateUpperActions
                  onClick={() => undefined}
                  theme="secondary"
                  label="Filter"
                  iconLeft="filter"
                />
              }
            >
              <div className="audit__content__header__filter__menu">
                <span className="audit__content__header__filter__title">Filter by Categories</span>
                <FlightCollapsible
                  className="audit__content__header__filter__categories"
                  maxHeight="unset"
                  items={collapsibleItems}
                  openMultiple
                />
              </div>
            </FlightDropdown>
          </div>
          {(filters.action.length !== 0 ||
            filters.eventType.length !== 0 ||
            filters.userType.length !== 0 ||
            filters.userSearchIds.length !== 0 ||
            filters.start > 0) && (
            <div className="audit__content__filter">
              <span className="audit__content__filter__title">Selected Filters:</span>
              <div className="audit__content__filter__labels-group">
                {filters.start > 0 &&
                  (filters.end - filters.start <= 86359 ? (
                    <FlightLabel label={`Date: ${moment.unix(filters.start).utc().format('DD/MM/YYYY')}`} />
                  ) : (
                    <FlightLabel
                      label={`Date: ${moment.unix(filters.start).utc().format('DD/MM/YYYY')} - ${moment
                        .unix(filters.end)
                        .utc()
                        .format('DD/MM/YYYY')}`}
                    />
                  ))}
                {filters.action.map((filter: string) => {
                  return (
                    <FlightLabel
                      key={'action-' + filter}
                      label={`Action: ${filter}`}
                      onRemove={() => handleOnActionFilterSelect(filter)}
                    />
                  );
                })}
                {filters.eventType.map((filter: string) => {
                  return (
                    <FlightLabel
                      key={'type-' + filter}
                      label={`Type: ${filter}`}
                      onRemove={() => handleOnTypeFilterSelect(filter)}
                    />
                  );
                })}
                {filters.userType.map((filter: string) => {
                  return (
                    <FlightLabel
                      key={'role-' + filter}
                      label={`User Role: ${filter}`}
                      onRemove={() => handleOnUserFilterSelect(filter)}
                    />
                  );
                })}
              </div>
            </div>
          )}
          <div className="audit__content__body">
            <FlightTable
              className="audit__content__body__table"
              tableHeaders={tableHeaders}
              hasPaginationBeforeTable={false}
              isLoading={isAuditLoading}
              hasPaginationAfterTable
              emptyState={<span>No logs found for this project.</span>}
              handleHeaderSort={handleOnHeaderSort}
              tableData={tableData}
              sortByKey={pagination.sortBy}
              sortOrder={pagination.sort.toLowerCase()}
              paginationProps={{
                totalPageNumber: getTotalPageNumber(),
                currentPageNumber: getCurrentPageNumber(),
                rowsPerPageOptions: [20, 40, 60, 80, 100],
                currentRowsPerPage: pagination.limit,
                handlePageChange: handleOnPageChange,
                handleRowsPerPageChange: handleOnRowPerPageChange,
              }}
            />
            {!isAuditLoading && tableData.length < 1 && (
              <div className="audit__content__empty">
                <div className="audit__content__empty__image--top-space">
                  <ContentEmptyAudit />
                </div>
                <span className="audit__content__empty__text audit__content__empty__text--main">No results found</span>
                <span className="audit__content__empty__text audit__content__empty__text--sub">
                  Try adjusting search or filter to find what you’re looking for.
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
