import { AppThunkDispatch, RootState } from 'store/store';
import {
  AudienceOptimization,
  ExperienceInstance,
  ExperienceTemplate,
  InstanceContentAction,
  InstancePushAction,
} from 'interface/experience/experience.interface';
import { ContentCreationPayload, isContentInstance } from 'interface/content/content.interface';
import { InstanceError, SystemError, TemplateError } from 'types/errors';
import { JOURNEY_STATUSES, JourneyStatuses } from 'store/journey/journey.type';
import { setInstance, setTemplate } from 'store/templated-experience/templated-experience.action';

import ContentAPI from 'services/api/content.api';
import { JourneyRule } from 'interface/rule/rule.interface';
import { PUSH_ACTION_TYPES } from 'store/push/push.type';
/* eslint-disable @typescript-eslint/no-explicit-any */
import TemplatedExperienceAPI from 'services/api/templated-experience.api';
import { deserializeError, genUUID } from 'helpers/common.helper';
import { generateErrorToast } from 'helpers/error.helper';
import { serializeJourney } from 'store/serializers';
import { validateJourney } from 'validators/ExperienceCanvas/journey.validator';
import merchantService from 'services/api/merchants.api';

const templatedExperienceAPI = new TemplatedExperienceAPI();
const contentAPI = new ContentAPI();

/**
 * This function grabs/sets the template. This differs from the one in templated-
 * experience actions by not attempting to set an instance, thus allowing the
 * SET_TEMPLATE action to be an update of sorts for constraints.
 */
export function fetchTemplate(templateId: string, preserveInstance = true) {
  return async (dispatch: any) => {
    try {
      const template = await templatedExperienceAPI.getTemplatedExperience(templateId);

      dispatch(setTemplate({ template, preserveInstance }));
      return template as ExperienceTemplate;
    } catch (err: any) {
      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: { content: 'Could not get the experience data', type: 'error' },
      });
      if (err?.response?.status === 500) {
        throw new TemplateError(`500: ${err?.response.data ?? 'unknown error'}`);
      } else {
        throw new SystemError(`${err?.response?.status}: ${err?.response.data ?? 'unknown error'}`);
      }
    }
  };
}

/**
 * This function grabs the instance. No fluff.
 */
export function fetchInstance(instanceId: string) {
  return async (dispatch: any) => {
    try {
      const instance = await templatedExperienceAPI.getTemplatedExperienceInstance(instanceId);
      dispatch(setInstance({ instance, updateNewReducer: true }));
      return instance as ExperienceInstance;
    } catch (err: any) {
      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: { content: 'Could not get the experience data', type: 'error' },
      });
      if (err?.response?.status === 500) {
        throw new InstanceError(`500: ${err?.response.data ?? 'unknown error'}`);
      } else {
        throw new SystemError(`${err?.response?.status}: ${err?.response.data ?? 'unknown error'}`);
      }
    }
  };
}

/**
 * This function pulls an instance, strips it of ids, then grabs/sets the template.
 **/
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function duplicateInstance(instanceId: string, templateId: string) {
  return async (dispatch: any) => {
    try {
      const instance: ExperienceInstance = await templatedExperienceAPI.getTemplatedExperienceInstance(instanceId);
      instance.name = `${instance.name || 'Untitled'} - Copy`;
      instance.status = JOURNEY_STATUSES.DRAFT;
      instance.metadata = {
        ...instance.metadata,
        // TODO: investigate why spreading itself fails the typecheck on templateName (why is templateName not optional)
        templateName: instance.metadata?.templateName ?? '',
        filesUUID: genUUID(),
      };
      delete instance.id;
      delete instance.activatedAt;
      delete instance.deactivatedAt;
      delete instance.updatedAt;

      for (const step of instance.steps) {
        delete step.experience;
        if (step.audience.preferred !== null) {
          stripRule(step.audience.preferred);
        }
        if (step.audience.restricted !== null) {
          stripRule(step.audience.restricted);
        }
        for (const action of step.actions) {
          if (
            action.actionType === PUSH_ACTION_TYPES.PUSH_NOTIFICATION ||
            action.actionType === PUSH_ACTION_TYPES.TRIGERRED_PUSH ||
            action.actionType === PUSH_ACTION_TYPES.BROADCAST_PUSH
          ) {
            stripPush(action);
          }

          if (action.actionType === 'content-instance') {
            await regenerateContent(action);
          }
        }
      }

      dispatch(setInstance({ instance, updateNewReducer: true }));
      return instance as ExperienceInstance;
    } catch (err: any) {
      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: { content: 'Could not duplicate experience', type: 'error' },
      });
      if (err?.response?.status === 500) {
        throw new InstanceError(`500: ${err?.response.data ?? 'unknown error'}`);
      } else {
        throw new SystemError(`${err?.response?.status}: ${err?.response.data ?? 'unknown error'}`);
      }
    }
  };
}

async function associateJourneyToParent(parentType: string, parentId: string, journeyId: string) {
  switch (parentType) {
    case 'offer':
      try {
        // TODO: mcfudging it rn because there ain't any single fetch endpoints
        const merchants = await merchantService.getMerchants({ limit: 999, includeOffers: true });
        const merchant = merchants.data?.find((m) => m.offers?.find((o) => o.id === parentId));
        const offer = merchant?.offers?.find((o) => o.id === parentId);
        if (!offer || offer?.journeyInstanceId) return;
        else
          await merchantService.updateOffer({
            ...offer,
            journeyInstanceId: journeyId,
          });
      } catch (e) {}
      return;
    default:
  }
}

/**
 * Saves the a journey, with an optional flag for saving as a draft
 */
export function saveJourneyInstance(overrideStatus?: JourneyStatuses) {
  return async (dispatch: any, getState: any) => {
    const state: RootState = getState();
    const status = overrideStatus ?? state.te.journey.status;
    const isUpdate = !!state.te.journey.id;

    const saveDraft = !status || status === JOURNEY_STATUSES.DRAFT;

    if (!saveDraft) {
      const isValid = validateJourney(state);
      if (!isValid) return;
    }

    const journeyInstance = serializeJourney(state, saveDraft, status);

    try {
      const res: any = isUpdate
        ? await templatedExperienceAPI.updateTemplatedExperienceInstance(journeyInstance)
        : await templatedExperienceAPI.createTemplatedInstance(journeyInstance);

      if (res.errors || res.error) {
        dispatch({
          type: 'UPDATE_JOURNEY',
          payload: {
            status: JOURNEY_STATUSES.ERROR,
          },
        });
        throw new Error('Partially Created ... Contact Support.');
      } else {
        // i'm actually not sure if we need to broadcast manually anymore :/
        let successVerb = '';

        if (overrideStatus) {
          if (overrideStatus === 'active') successVerb = 'launched';
          if (overrideStatus === 'scheduled') successVerb = 'scheduled';
        } else {
          successVerb = 'saved';
        }

        // associate id back to parent
        if (journeyInstance.parentEntityType && journeyInstance.parentEntityId) {
          await associateJourneyToParent(journeyInstance.parentEntityType, journeyInstance.parentEntityId, res.id);
        }

        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: { content: `Successfully ${successVerb} experience.`, type: 'success' },
        });
        // right now this is the fastest way to fix the issue of overwriting
        // template constraints.
        // we could also look into dispatching another settemplate call
        // or adding capability for a soft update to the setinstance reducers
        dispatch({
          type: 'UPDATE_JOURNEY',
          payload: {
            id: res.id,
            name: res.name,
            status,
            createdAt: res.createdAt,
            updatedAt: res.updatedAt,
            activatedAt: res.activatedAt,
          },
        });
      }
    } catch (err: any) {
      let errors = Array.isArray(state.te.journey.errors) ? state.te.journey.errors : [];
      errors = errors.concat(deserializeError(err));

      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: { title: 'Error!', content: generateErrorToast(errors, state), type: 'error' },
      });
      dispatch({
        type: 'UPDATE_JOURNEY',
        payload: { errors },
      });
    }
  };
}

/**
 * Inserts a blank step
 */
export function insertJourneyStep() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (dispatch: AppThunkDispatch, getState: () => RootState) => {
    dispatch({
      type: 'INSERT_STEP',
    });
  };
}

/**
 * Removes a step
 */
export function removeJourneyStep(stepIdx: number) {
  return (dispatch: AppThunkDispatch, getState: () => RootState) => {
    const { push, rule, content } = getState().te.journey.steps[stepIdx];
    dispatch({
      type: 'REMOVE_STEP',
      payload: {
        stepIdx: stepIdx,
        push,
        rule,
        content,
      },
    });
  };
}

export function activateJourneyInstance(id: string) {
  return async (dispatch: any) => {
    try {
      const res = await templatedExperienceAPI.activateTemplatedExperienceInstance(id);
      if (res.status?.toLowerCase() === 'active') {
        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: {
            content: `The experience has been ACTIVATED`,
            type: 'default',
          },
        });
        dispatch({
          type: 'UPDATE_JOURNEY',
          payload: {
            status: JOURNEY_STATUSES.ACTIVE,
          },
        });
        return res;
      }
    } catch (err: any) {
      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: {
          content: err?.response?.data ?? err?.message ?? 'An unknown error occurred during activation',
          type: 'error',
        },
      });
    }
  };
}

export function deactivateJourneyInstance(id: string) {
  return async (dispatch: any) => {
    try {
      const res = await templatedExperienceAPI.deactivateTemplatedExperienceInstance(id);
      if (res.status?.toLowerCase() === 'inactive') {
        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: {
            content: `The experience has been DEACTIVATED`,
            type: 'default',
          },
        });
        dispatch({
          type: 'UPDATE_JOURNEY',
          payload: {
            status: JOURNEY_STATUSES.INACTIVE,
          },
        });
        return res;
      }
      // our version of typescript (before 4) doesn't actually cast error as unknown,
      // sor our compiler will be fine (but your linter might not be)
    } catch (err: any) {
      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: {
          content: err?.response?.data ?? err?.message ?? 'An unknown error occurred during deactivation',
          type: 'error',
        },
      });
    }
  };
}

// adjust draft settings, delete id, name, headname
function stripRule(rule?: JourneyRule) {
  if (!rule) return;

  delete rule.id;
  if (!rule.payload) return;
  rule.payload.name = '';
  rule.payload.headName = '';
  rule.payload.isDraft = true;
  rule.payload.visibility = 'draft';
}

// replace ids with variables
function stripPush(push: InstancePushAction) {
  delete push.id;
  if (push.payload?.body?.[0]?.payload?.data?.contentId?.value)
    push.payload.body[0].payload.data.contentId.value = '{{dep.content-id.0}}';
  if (push.payload?.rules?.[0]?.ruleID) push.payload.rules[0].ruleID = '{{dep.rule-id.0}}';
}

// re-generates a content based on the existing one
async function regenerateContent(contentAction: InstanceContentAction) {
  delete contentAction.dependencies;
  if (!contentAction.payload || !isContentInstance(contentAction.payload)) return;
  const { content, labels = [], ...contentPayload } = contentAction.payload;
  labels.push('fb-auto-gen');
  const createData: any = {};

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { _id, ...data } = content?.data?.[0];
  for (const dataKey in data) {
    if (!dataKey.includes('pagination')) {
      createData[dataKey] = data[dataKey];
    }
  }

  const contentCreationPayload: ContentCreationPayload = {
    ...contentPayload,
    labels: labels,
    data: createData,
  };

  const createdInstance = await contentAPI.createInstance(contentCreationPayload);

  contentAction.id = createdInstance.id;
  contentAction.payload = createdInstance;
}

/**
 * Updates the audience optimization fields of a journey
 */
export function updateJourneyAO(updatedAOPayload: AudienceOptimization, startEvent?: () => Promise<any> | void) {
  return async (dispatch: any, getState: any) => {
    const state: RootState = getState();
    state.te.journey.audienceOptimization = updatedAOPayload;

    const journeyInstance = serializeJourney(state, false, state.te.journey.status);
    const res = await templatedExperienceAPI.updateTemplatedExperienceInstance(journeyInstance);
    if (startEvent) await startEvent();

    if (!res.errors) {
      dispatch({
        type: 'UPDATE_JOURNEY',
        payload: {
          audienceOptimization: updatedAOPayload,
        },
      });
    }
  };
}
