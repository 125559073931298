/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// common.helper.ts
import { v4 } from 'uuid';
import { getTenantId } from 'helpers/templated-experience.helper';
import { FlybitsError, FullTime } from 'types/common';
import { ApprovalRequest } from 'interface/approval/approval.interface';
import Storage from 'services/storage';
import axios, { isAxiosError } from 'axios';
import moment from 'moment';
import { SEARCH_RANKINGS } from 'pages/Analytics/CustomModuleAnalytics/constants';

export const genUUID = (setNil = false) => {
  return setNil ? '00000000-0000-0000-0000-000000000000' : v4().toUpperCase();
};
export function groupBy<T>(arr: T[], fn: (item: T) => any) {
  return arr.reduce<Record<string, T[]>>((prev, curr) => {
    const groupKey = fn(curr);
    const group = prev[groupKey] || [];
    group.push(curr);
    return { ...prev, [groupKey]: group };
  }, {});
}
/**
 * Generate url for image asset.  Maybe be more build-safe to replace calls to
 * this function with imports of the assets directly.
 * */
export const getImageUrl = (name?: string, dir = 'assets/icons') => {
  if (!name) return '';
  return new URL(`../${dir}/${name}`, import.meta.url).href;
};

export const epochToDateTimeString = (
  epochOrDate: number | Date,
  locale = 'en-US',
  options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
  },
) => {
  const epochValue = typeof epochOrDate === 'number' ? epochOrDate : epochOrDate.getTime();
  return new Date(epochValue).toLocaleString(locale, options);
};

// moment is depreciated, but the timezones are a hassle to deal with so uh yeah
export const convertEpochToTime = (timestamp?: number | 'NOW', timezone?: string, omit?: boolean): FullTime => {
  if (!timestamp) return { isActive: omit !== undefined ? !omit : false };

  const date = timestamp === 'NOW' ? moment() : moment.unix(timestamp);
  if (timezone) {
    // we want to convert the timezone to a format moment can understand, like "America/New_York"
    const formattedTimezone = timezone.substring(0, timezone.indexOf('(')).trim().replace(' ', '_');
    date.tz(formattedTimezone);
  }
  const seconds = moment.duration(date.format('HH:mm')).asSeconds();
  return {
    isActive: omit !== undefined ? !omit : true,
    date: date.clone().startOf('day').toDate(),
    time: {
      key: seconds,
      name: date.format('hh:mm A'),
      value: seconds,
    },
    shortDateString: date.format('ddd, MMM D, YYYY'),
  };
};

export function getISODate(date?: Date) {
  if (!date || (!(date instanceof Date) && isNaN(date))) return '';
  if (date.toString() == 'Invalid Date') return '';
  return (date as Date).toISOString().split('T')[0] || '';
}

/**
 * Unix timestamp to ISO String with an offset.
 * Offset should be given in milliseconds.
 * */
export function unixToOffsetISO(timestamp: number, offset = 0) {
  return new Date(timestamp * 1000 - offset).toISOString().slice(0, -8);
}

/**
 * ISO String to Unix timestamp with an offset.
 * Offset should be given in milliseconds.
 * */
export function offsetISOToUnix(isoDateTime: string, offset = 0) {
  return (new Date(`${isoDateTime}Z`).getTime() + offset) / 1000;
}

/**
 * @returns current timestamp in seconds
 */
export function getCurrentTimestampInMs() {
  return Math.floor(new Date().getTime() / 1000);
}

/**
 * Format file size to a human readable format.
 * */
export function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function deserializeError(err: any | unknown): any[] {
  let errors: any[] = [];
  if (err.response?.data?.errors) errors = errors.concat(err.response.data.errors);
  else if (err.response?.data?.error) errors.push(err.response.data.error);
  return errors;
}

// functions potentially for custom pushes or errors in the future.
// i'm not sure how to get around the type any situation - by nature these functions are pretty meta.
export function setAttributeByPath<T extends Record<string, any>>(inputObj: T, path: string, value: any): T {
  const retVal: T = JSON.parse(JSON.stringify(inputObj));
  const convertedPath = path.replace(/\[(\w+)\]/g, '.$1');
  const keyChain = convertedPath.split('.');
  let currentAttr: any = retVal;
  for (let i = 0, n = keyChain.length; i < n; i++) {
    const key = keyChain[i];
    if (key in currentAttr && i + 1 < n) {
      currentAttr = currentAttr[key];
    } else if (!(key in currentAttr) && i + 1 < n) {
      currentAttr[key] = {};
      currentAttr = currentAttr[key];
    } else {
      currentAttr[key] = value;
    }
  }

  return retVal;
}

export function getAttributeByPath(inputObj: any, path: string) {
  // convert indexes to properties
  const convertedPath = path.replace(/\[(\w+)\]/g, '.$1');
  const keyChain = convertedPath.split('.');
  let currentAttr: any = inputObj;
  for (let i = 0, n = keyChain.length; i < n; ++i) {
    const key = keyChain[i];
    if (key in currentAttr) {
      currentAttr = currentAttr[key];
    } else {
      return;
    }
  }
  return currentAttr;
}

/**
 * Checks if what approvals role a user is.
 * */
export function getUserApprovalPermissions(
  userId: string,
  creators: ApprovalRequest['creators'],
  approvalRequirements: ApprovalRequest['approvalRequirements'],
) {
  const isAuthor = !!creators.find((creator) => creator.userId === userId);
  let matchedReviewer = approvalRequirements.reviewers?.find((reviewer) => reviewer.userId === userId);
  if (approvalRequirements.teams?.length)
    for (let i = 0; i < approvalRequirements.teams.length; i++) {
      if (matchedReviewer) break;
      const team = approvalRequirements.teams[i];

      matchedReviewer = matchedReviewer || team.reviewers?.find((reviewer) => reviewer.userId === userId);
    }
  return { isAuthor, isReviewer: !!matchedReviewer, hasApproved: !!matchedReviewer?.approved };
}

export const uniqRand = <T>(generator: (...args: unknown[]) => T) => {
  const used: T[] = [];

  return (...args: unknown[]) => {
    let value;
    do {
      value = generator(...args);
    } while (used.includes(value));

    used.push(value);

    return value;
  };
};
/**
 * Returns a ranked search by best match
 * */
export const rankedSearch = (arr: any[], key: string | number, searchText: string) => {
  const searchTextLowercased = searchText.toLocaleLowerCase();
  const rankedIndex = arr
    .map((entry) => {
      const entryName = entry[key].toLocaleLowerCase();
      // too long
      if (entryName.length < searchText.length) {
        return { ...entry, points: SEARCH_RANKINGS.NO_MATCH };
      }
      if (entry[key] === searchText) {
        return { ...entry, points: SEARCH_RANKINGS.CASE_SENSITIVE_EQUAL };
      }
      if (entryName.toLocaleLowerCase() === searchTextLowercased) {
        return { ...entry, points: SEARCH_RANKINGS.EQUAL };
      }
      if (entryName.toLocaleLowerCase().startsWith(searchTextLowercased)) {
        return { ...entry, points: SEARCH_RANKINGS.STARTS_WITH };
      }
      if (entryName.toLocaleLowerCase().includes(searchTextLowercased)) {
        return { ...entry, points: SEARCH_RANKINGS.CONTAINS };
      }
      return { ...entry, points: 0 };
    })
    .sort((a, b) => b.points - a.points)
    .filter(({ points }) => points > 0);
  return rankedIndex;
};

export type TTimeoutId = { timeoutId?: number };

export const debounce = <T extends Array<any>, K>(callback: (...args: T) => K, wait: number, id?: TTimeoutId) => {
  let timeoutId: number | undefined = id?.timeoutId;

  return (...args: Parameters<typeof callback>) =>
    new Promise<K>((resolve) => {
      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => {
        resolve(callback(...args));
      }, wait);
      if (id) id.timeoutId = timeoutId;
    });
};

export const disableLoading = (startTime: number, callback: () => void, minimumLoadingWait = 1200) => {
  return new Promise((r) => {
    const nowDate = Date.now();
    const elapsedTime = nowDate - startTime;

    if (elapsedTime >= minimumLoadingWait) {
      // if the user already waited the proper time, then release loading right away
      if (callback) callback();
      return r;
    } else {
      // otherwise, wait the minimum loading wait time
      setTimeout(() => {
        if (callback) callback();
        return r;
      }, minimumLoadingWait - elapsedTime);
    }
  });
};

export const sleep = (milliseconds = 400) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const intlNumberFormat = (number: number, local = 'en-US', options = { maximumFractionDigits: 2 }) =>
  new Intl.NumberFormat(local, options).format(number);

// The getFormikFieldErrorNames() function takes a Formik error as an argument,
// and returns an array of error field names using object dot-notation for array fields.
// For instance, given the error object from above, it'll return ['friends.0.name', 'friends.1.email'],
// meaning that these attributes at that index have a validation error.
export const getFormikFieldErrorNames = (formikErrors: any) => {
  const transformObjectToDotNotation = (errorObj: any, prefix = '', result: string[] = []) => {
    Object.keys(errorObj).forEach((key) => {
      const value = errorObj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === 'object') {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

/**
 * Fires off an image upload and returns the uploaded images' url.
 * Fails silently (returns undefined) for now.
 * @param file
 * @returns url
 */
export const uploadImageFile = async (file?: any): Promise<string> => {
  if (!file || !(file instanceof File)) throw new Error('You cannot upload a non-file object!');
  const fm = new FormData();
  const storage = new Storage();

  const tenantId = getTenantId();
  const token = await storage.getItem(`${tenantId}+token`);

  fm.append('image', file);
  fm.append('proxyTarget', `${process.env.REACT_APP_API_URL}/kernel/file-manager/files/upload`);
  fm.append(
    'proxyHeaders',
    JSON.stringify({
      'x-authorization': token,
    }),
  );
  fm.append('proxyFileParam', 'image');

  try {
    const { data } = await axios.post(`${process.env.REACT_APP_PIXELHOST_URL}/upload`, fm);
    return data[0]?.url ?? '';
  } catch (err) {
    if (isAxiosError(err)) throw err;
    else throw new Error('The image upload could not be completed');
  }
};

export const capitalizeFirstCharacter = (word: string) => (word ? `${word.at(0)?.toUpperCase()}${word.slice(1)}` : '');

export const isFBError = (errorData: unknown): errorData is FlybitsError => {
  return typeof (errorData as FlybitsError)?.error?.exceptionMessage === 'string';
};
