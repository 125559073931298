import { useState, useEffect } from 'react';
import useZoneConfig from 'hooks/useZoneConfig';
import {
  ModuleErrorMessages,
  PublishChangesBannerInfo,
  TPrioritizationContext,
  initialModuleErrorMessages,
  initialPublishChangesBannerInfo,
  initialZoneErrorMessages,
  ShowContentManagerSlideOutProps,
} from './PrioritizationContext';
import { Zone, Module } from 'pages/Zones/types';
import { ZoneErrorMessages } from 'components/Zones/v2/ZoneAttributes/ZoneAttributes';
import { genUUID } from 'helpers/common.helper';
import { diffObject } from 'components/Zones/v2/zone.validators';
import { ModuleDefaults, ZoneDefaults } from 'components/Zones/v2/ZoneModuleSetup/constants';
import { sanitizeZoneConfigPayload } from 'components/Zones/v2/zones.helpers';
import { MODULE_LAYOUTS } from 'components/Zones/v2/types';

// Remove this after BE migration from `pinnedContentIDs` -> `pinnedContentIDsByTimeRange` is complete
function removeLegacyFields(zones: Zone[]) {
  zones.forEach((zone) => {
    zone.modules.forEach((module) => {
      delete module.pinnedContentIDs;
      delete module.labelsLimits;
      delete module.labelsOrder;
    });
  });

  return zones;
}

// maybe we can have a weird little reducer if ppl feel this gets too heavy or complex
const usePrioritizationContext = (): TPrioritizationContext => {
  const { zoneConfigData, zoneConfigMutation, isZoneConfigLoading, queryKey } = useZoneConfig();
  const [isInitialized, setIsInitialized] = useState(!isZoneConfigLoading);
  const [zones, setZones] = useState<Zone[]>(zoneConfigData.zones);
  const [selectedZone, setSelectedZone] = useState<Partial<Zone>>(ZoneDefaults);
  const [zoneErrorMessages, setZoneErrorMessages] = useState<ZoneErrorMessages>(initialZoneErrorMessages);
  const [selectedModule, setSelectedModule] = useState<Partial<Module>>(ModuleDefaults);
  const [moduleErrorMessages, setModuleErrorMessages] = useState<ModuleErrorMessages>(initialModuleErrorMessages);
  const [isLabelManagementOpen, setLabelManagementOpen] = useState(false);
  const [isPinnedContentScheduleOpen, setPinnedContentScheduleOpen] = useState(false);
  const [publishChangesBannerInfo, setPublishChangesBannerInfo] = useState<PublishChangesBannerInfo>(
    initialPublishChangesBannerInfo,
  );
  const [numUnsavedChanges, setNumUnsavedChanges] = useState(0);
  const [labelAndOrderedContentSelectedModuleId, setLabelAndOrderedContentSelectedModuleId] = useState<string | null>(
    null,
  );
  const [showModuleDetailsSlideout, setShowModuleDetailsSlideout] = useState(false);
  const [isCustomLayout, setCustomLayout] = useState(false);

  const [showContentManagerSlideout, setShowContentManagerSlideout] = useState<ShowContentManagerSlideOutProps>({
    show: false,
  });

  const resetZoneConfigs = () => {
    setZones(zoneConfigData.zones);
  };

  const resetSelectedZone = () => {
    setSelectedZone(ZoneDefaults);
  };

  const publishZoneConfigs = async (zonesOverride?: Zone[]) => {
    const sanitizedPayload = sanitizeZoneConfigPayload(zonesOverride ?? zones);

    // Remove this after BE migration from `pinnedContentIDs` -> `pinnedContentIDsByTimeRange` is complete
    // Remove old labelsLimits and labelsOrder
    const _zones = removeLegacyFields(sanitizedPayload);

    const id = zoneConfigData.id;
    return zoneConfigMutation.mutateAsync({ id, zones: _zones });
  };

  const addZone = (zone: Zone) => {
    const newZone = { ...zone, id: genUUID() };

    setZones((zones) => [...zones, newZone]);

    return newZone;
  };

  const updateZone = (updatedZone: Zone) => {
    setZones((zones) =>
      zones.map((zone) => {
        if (zone.id === selectedZone.id) {
          return updatedZone;
        } else {
          return zone;
        }
      }),
    );
  };

  const removeZone = (removedZone: Zone) => {
    setZones((zones) => zones.filter((zone) => zone.id !== removedZone.id));
  };

  const removeModule = (selectedZoneId: string, moduleToBeRemoved: Module) => {
    setZones((zones) =>
      zones.map((zone) => {
        if (zone.id === selectedZoneId) {
          return {
            ...zone,
            modules: zone.modules?.filter((module) => module.id !== moduleToBeRemoved.id),
          };
        } else {
          return zone;
        }
      }),
    );
  };

  const addModule = (selectedZoneId: string, module: Module) => {
    const newModule = { ...module, id: genUUID() };

    setZones((zones) =>
      zones.map((zone) => {
        if (zone.id === selectedZoneId) {
          return {
            ...zone,
            modules: zone.modules?.length ? [...zone.modules, newModule] : [newModule],
          };
        } else {
          return zone;
        }
      }),
    );
  };

  const updateModule = (selectedZoneId: string, updatedModule: Module) => {
    setZones((zones) =>
      zones.map((zone) => {
        if (zone.id === selectedZoneId) {
          return {
            ...zone,
            modules: zone.modules.map((module) => (module.id !== updatedModule.id ? module : updatedModule)),
          };
        } else {
          return zone;
        }
      }),
    );
  };

  useEffect(() => {
    if (isZoneConfigLoading) {
      return;
    }

    // Add placeholders for `modules` and `labels`
    zoneConfigData.zones.forEach((zone) => {
      if (!Array.isArray(zone.labels)) {
        zone.labels = [];
      }

      if (!Array.isArray(zone.modules)) {
        zone.modules = [];
      }
    });
    setZones(zoneConfigData.zones);
    setIsInitialized(true);
  }, [zoneConfigData.zones, isZoneConfigLoading]);

  useEffect(() => {
    if (isZoneConfigLoading) {
      return;
    }

    const checkForChanges = (currentData: unknown, defaultData: unknown) => {
      const diffs = diffObject(defaultData, currentData, true);
      // It returns the number of added, updated, and removed
      return Object.keys(diffs.added).length + Object.keys(diffs.updated).length + Object.keys(diffs.removed).length;
    };

    const numUnsavedChanges = checkForChanges(zones, zoneConfigData.zones);
    setNumUnsavedChanges(numUnsavedChanges);

    if (numUnsavedChanges > 0) {
      setPublishChangesBannerInfo((bannerInfo) => ({ ...bannerInfo, show: true }));
    } else {
      setPublishChangesBannerInfo((bannerInfo) => ({ ...bannerInfo, show: false }));
    }
  }, [zones, zoneConfigData.zones, isZoneConfigLoading]);

  // Since there is no such layout as `custom` in the BE
  // Derive `Custom` layout in `ModuleInfo` tab if `layoutHTML` is set
  useEffect(() => {
    if (
      selectedModule?.layout === MODULE_LAYOUTS.VERTICAL ||
      selectedModule?.layout === MODULE_LAYOUTS.HORIZONTAL ||
      selectedModule?.layout === MODULE_LAYOUTS.EXPOSE ||
      (selectedModule?.layout === undefined && selectedModule?.layoutHTML === undefined)
    ) {
      setCustomLayout(false);
    } else {
      setCustomLayout(true);
    }
  }, [selectedModule]);

  return {
    backEndZoneConfigs: zoneConfigData,
    isZoneConfigLoading: !isInitialized,
    zones,
    setZones,
    selectedZone,
    setSelectedZone,
    zoneErrorMessages,
    setZoneErrorMessages,
    addZone,
    updateZone,
    removeZone,
    removeModule,
    resetSelectedZone,
    selectedModule,
    setSelectedModule,
    moduleErrorMessages,
    setModuleErrorMessages,
    resetZoneConfigs,
    publishZoneConfigs,
    queryKey,
    isLabelManagementOpen,
    setLabelManagementOpen,
    isPinnedContentScheduleOpen,
    setPinnedContentScheduleOpen,
    addModule,
    updateModule,
    publishChangesBannerInfo,
    setPublishChangesBannerInfo,
    numUnsavedChanges,
    labelAndOrderedContentSelectedModuleId,
    setLabelAndOrderedContentSelectedModuleId,
    showModuleDetailsSlideout,
    setShowModuleDetailsSlideout,
    showContentManagerSlideout,
    setShowContentManagerSlideout,
    isCustomLayout,
    setCustomLayout,
  };
};

export default usePrioritizationContext;
